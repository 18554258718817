<template>
  <mm-modal v-model="$store.state.isAppMaintenanceOngoing" width="80%" persistent>
    <div class="d-flex-col d-flex-center p-a-3">
      <img style="max-height: 30px" class="m-b-8" src="@/assets/images/identity/mammoth_logo.png" alt="logo" />
      <img
        style="max-height: 35vh"
        class="m-b-6"
        src="@/assets/images/illustrations/maintenance.svg"
        :alt="$t('global.maintenance.title')"
      />
      <p class="mm-text--h600">{{ $t('global.maintenance.title') }}</p>
      <p class="mm-text--h400 m-b-6">{{ $t('global.maintenance.description') }}</p>

      <i18n path="global.maintenance.status" tag="span">
        <template #statusUrl> <mm-link :label="STATUS_URL.replace('https://', '')" :href="STATUS_URL" /> </template>
      </i18n>

      <mm-divider class="m-t-7 m-b-4" />

      <div class="m-l-auto d-flex-align-center">
        <mm-link :label="$t('global.contact_support')" @click="mixinFreshdeskWidget_showSupportWidget" />
      </div>
    </div>
  </mm-modal>
</template>

<script>
// Constants
import { STATUS_URL } from '@/constants'

// Mixins
import FreshdeskWidget from '@/mixins/support/freshdeskWidget'

export default {
  name: 'app-maintenance',
  mixins: [FreshdeskWidget],
  created() {
    this.STATUS_URL = STATUS_URL
  }
}
</script>
