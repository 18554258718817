// API
import API from '@/api'
import { ENV_KEY } from '@/constants'

// Store
import store from '@/plugins/store'

let cbInstance

export function appendChargebeeScript() {
  let chargebeeScript = document.createElement('script')
  chargebeeScript.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js')
  document.head.appendChild(chargebeeScript)
}

function initializeChargebee() {
  cbInstance = window.Chargebee.init({ site: ENV_KEY.VITE_APP_CHARGEBEE_SITE })
}

export function changeChargebeePlan(plan, onSuccessCloseFn) {
  if (!cbInstance) initializeChargebee()
  let isChangeSuccessful = false

  cbInstance.openCheckout({
    hostedPage: () => {
      return API.post(`workspaces/${store.state.workspaceId}/sms-details/hosted-page`, {
        object_type: 'change_plan',
        plan_id: plan.id
      }).then((response) => response.data)
    },
    success: async () => {
      isChangeSuccessful = true

      store.dispatch('getSmsDetails', { fetchAllDetails: true })
    },
    close: () => {
      if (isChangeSuccessful) onSuccessCloseFn?.()
    }
  })
}

export function openChargebeePortal(sectionType) {
  if (!cbInstance) initializeChargebee()

  cbInstance.setPortalSession(() => {
    return API.post(`workspaces/${store.state.workspaceId}/sms-details/hosted-page`, {
      object_type: 'portal_session'
    }).then((response) => response.data)
  })

  let cbPortal = cbInstance.createChargebeePortal()
  cbPortal.openSection(
    { sectionType, params: { subscriptionId: store.state.smsDetails.subscriptionId } },
    {
      close: async () => store.dispatch('getSmsDetails', { fetchAllDetails: true })
    }
  )
}

export const getChargebeePortalSections = () => window.Chargebee?.getPortalSections()
