// Plugins
import i18n from '@/plugins/i18n'

// Dependencies
import { decode } from 'html-entities'

export const randomString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  return Array(length)
    .fill('')
    .map(() => Math.floor(Math.random() * chars.length))
    .map((r) => chars.charAt(r))
    .join('')
}

export const getExportTooltip = (dependency) => {
  let text = ''

  if (dependency.host)
    text += `${i18n.t('data_library.data_addition.third_party.new_connection.host_url')}: ${dependency.host}<br>`
  if (dependency.database)
    text += `${i18n.t('data_library.data_addition.third_party.new_connection.database')}: ${dependency.database}<br>`
  if (dependency.table) text += `${i18n.t('global.dictionary.table')}: ${dependency.table}<br>`
  if (dependency.indexName) text += `${i18n.t('global.dictionary.index_name')}: ${dependency.indexName}<br>`
  if (dependency.directory) text += `${i18n.t('global.dictionary.directory')}: ${dependency.directory}<br>`

  return text
}

export const createFuzzyRegex = (pattern) => {
  // Escape special regex characters in the pattern
  const escapedPattern = pattern.replace(/[-/^$*+?.()|[\]{}]/g, '')
  // Create a regex pattern that matches each character in the pattern in order, with any characters in between
  const fuzzyPattern = escapedPattern
    .split('')
    .map((char) => `${char}.*`)
    .join('')
  return new RegExp(fuzzyPattern, 'i') // 'i' flag makes the match case-insensitive
}

export const highlightMatches = (pattern, text, startTag = '<strong>', endTag = '</strong>') => {
  const fuzzyRegex = createFuzzyRegex(pattern)

  // Initialize the result string and position tracking
  let result = ''
  let lastIndex = 0

  // Use regex to find all matches
  String(text).replace(fuzzyRegex, (match, offset) => {
    // Find positions of each character in the match within the original text
    let patternIndex = 0
    for (let i = 0; i < match.length; i++) {
      if (patternIndex < pattern.length && match[i].toLowerCase() === pattern[patternIndex].toLowerCase()) {
        if (lastIndex < offset + i) result += text.substring(lastIndex, offset + i) // Add non-matching part
        result += `${startTag}${match[i]}${endTag}` // Add matching part
        lastIndex = offset + i + 1
        patternIndex++
      }
      if (i == match.length - 1) result += text.substring(lastIndex)
    }
  })

  return result || text
}

export const flattenObject = (obj, parent = '', flattened = {}) => {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key
    if (typeof obj[key] === 'object') flattenObject(obj[key], propName, flattened)
    else flattened[propName] = obj[key]
  }
  return flattened
}

export const getUniqueName = (baseName, existingNames) => {
  let newName = baseName
  let counter = 1
  while (existingNames.includes(newName)) {
    newName = baseName ? `${baseName} ${counter}` : counter
    counter++
  }
  return String(newName)
}

export const deepDecode = (obj, preventHTMLRender) => {
  if (Array.isArray(obj)) return obj.map((el) => deepDecode(el, preventHTMLRender))

  if (obj !== null && typeof obj === 'object') {
    const decodedObj = {}
    for (const key in obj) decodedObj[key] = deepDecode(obj[key], preventHTMLRender)
    return decodedObj
  }

  if (typeof obj === 'string') {
    const decodedString = decode(obj)
    if (preventHTMLRender)
      return decodedString
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
    return decodedString
  }

  return obj
}

export const getNumberFormatLabel = (format) => {
  let label = ''
  if (format.separator) label += ' , |'
  if (format.percentage) label += ' % |'
  else if (format.currency) label += ` ${format.currency} |`
  label += format.decimalPrecision > 0 ? ` 0.${'0'.repeat(format.decimalPrecision)}` : ' 0 '
  return label
}
