import moment from 'moment'
import * as FPP from 'formatter-plus-plus'

const FormatterPlusPlus = FPP.FormatterPP
const formatterPlusPlus = new FormatterPlusPlus()

//Returns a common way of displaying time handled by moment#fromNow. This is sometimes called timeago or relative time.
export function getFromNowDate(date) {
  return moment.utc(date).local().fromNow()
}

export function convertNumberToAlphabet(number) {
  let result = ''
  do {
    const letter = String.fromCharCode(65 + (number % 26))
    result = letter + result
    number = Math.floor(number / 26) - 1
  } while (number >= 0)
  return result
}

export function compactNumberFormat(number) {
  return Intl.NumberFormat('en', { notation: 'compact' }).format(number)
}

export function humanizeNumber(number) {
  return formatterPlusPlus.humanize(number)
}
