export const STEP_STATUS = {
  ADDED: 'added',
  DELETED: 'deleted',
  EDITED: 'edited',
  EXECUTED: 'executed',
  SUSPENDED: 'suspended',
  SUSPENDING: 'suspending'
}
export const STEP_CONTEXT = {
  ADD: 'add',
  DUPLICATE: 'duplicate',
  EDIT: 'edit'
}

export const STEP_TYPES = {
  ACTION: 'action',
  REPORT: 'report',
  TASK: 'task'
}

export const STEP_SUBTYPES = {
  ADD_COLUMN: 'ADD_COLUMN',
  BRANCH_OUT: 'BRANCH_OUT',
  BULK_COPY: 'BULK_COPY',
  BULK_REPLACE: 'BULK_REPLACE',
  COMBINE: 'COMBINE',
  CONCATENATE: 'CONCATENATE',
  CONVERT: 'CONVERT',
  COPY: 'COPY',
  CROSSTAB: 'CROSSTAB',
  DELETE: 'DELETE',
  DATE_DIFF: 'DATE_DIFF',
  DISCARD_DUPLICATES: 'DISCARD_DUPLICATES',
  EXPORT: 'EXPORT',
  EXTRACT_DATE: 'EXTRACT_DATE',
  FILL: 'FILL',
  INCREMENT_DATE: 'INCREMENT_DATE',
  JOIN: 'JOIN',
  JSON_HANDLE: 'JSON_HANDLE',
  LIMIT: 'LIMIT',
  LIVE_LINK: 'LIVE_LINK',
  LOOKUP: 'LOOKUP',
  MATH: 'MATH',
  OBTAIN_LARGE_OR_SMALL: 'OBTAIN_LARGE_OR_SMALL',
  PIVOT: 'PIVOT',
  PUBLISH: 'PUBLISH',
  REPLACE: 'REPLACE',
  SELECT: 'SELECT',
  SET: 'SET',
  SPLIT: 'SPLIT',
  SUBSTRING: 'SUBSTRING',
  TEXT_TRANSFORM: 'TEXT_TRANSFORM',
  UNNEST: 'UNNEST',
  WINDOW: 'WINDOW'
}
