export const EXPLORE_CARD_TYPES = {
  LIST: 'list',
  CHART: 'chart'
}

export const AGGREGATION_OPTIONS = ['COUNT', 'SUM', 'AVG', 'MIN', 'MAX', 'STDDEV']

export const DATE_GRANULARITY_OPTIONS = [
  {
    id: 'DAY',
    format: '%d-%B-%Y',
    listFormat: 'dd-mmm-yyyy',
    graphFormat: 'd'
  },
  {
    id: 'MONTH',
    format: '%B, %Y',
    listFormat: "mmm 'yy",
    graphFormat: 'mmm-yyyy'
  },
  {
    id: 'YEAR',
    format: '%Y',
    listFormat: 'yyyy',
    graphFormat: 'yyyy'
  },
  {
    id: 'HOUR',
    format: '%d-%B-%Y %H:%M',
    listFormat: 'dd-mmm-yyyy hh:00',
    graphFormat: 'h tt'
  },
  {
    id: 'MINUTE',
    format: '%d-%B-%Y %H:%M',
    listFormat: 'dd-mmm-yyyy hh:MM',
    graphFormat: 'h:MM tt'
  },
  {
    id: 'SECOND',
    format: '%d-%B-%Y %H:%M:%S',
    listFormat: 'dd-mmm-yyyy hh:MM:ss',
    graphFormat: 'h:MM:ss tt'
  }
]

export const SORT_TYPES = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
}

export const MAX_SAMPLE_RUN_COUNT = 10
export const DATE_GRANULARITY_ORDER = ['YEAR', 'MONTH', 'DAY', 'HOUR', 'MINUTE', 'SECOND']

export const DATE_SERVER_SIDE_FORMAT = '%Y-%m-%d %H:%M:%S'
