<template>
  <mm-modal v-model="$store.state.isWorkspaceSnoozed" persistent>
    <div class="d-flex-col d-flex-center p-a-7">
      <img class="m-b-6" src="@/assets/images/illustrations/sun.svg" :alt="$t('workspace.snoozed.powering_up')" />
      <p class="mm-text--h600">{{ $t('workspace.snoozed.powering_up') }}</p>
      <p class="mm-text--h200 m-b-2">{{ $t('workspace.snoozed.getting_ready') }}</p>
      <mm-loader class="m-t-5" size="medium" />
    </div>
  </mm-modal>
</template>

<script>
export default {
  name: 'snoozed-workspace'
}
</script>
