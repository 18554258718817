import Vue from 'vue'
import VueRouter from 'vue-router'

// Plugins
import store from '@/plugins/store'

let routes = []

if (!import.meta.env.VITEST) {
  Vue.use(VueRouter)
  const modules = import.meta.globEager('../modules/**/*.routes.js')
  Object.keys(modules).forEach((filePath) => (routes = routes.concat(modules[filePath].default)))
}

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
  document.body.style.cursor = 'wait'

  if (store.getters.isWorkspaceIncomplete && to.name != 'Workspace') next({ name: 'Workspace', params: store.state })
  else if (store.getters.isOnboardingDataLibraryActive && to.name != 'DataLibraryOnboarding')
    next({ name: 'DataLibraryOnboarding', params: store.state })
  else if (
    store.getters.isWorkspaceTrialExpired &&
    to.name != 'TrialExpired' &&
    !to.name?.toLowerCase().includes('settings')
  ) {
    next({ name: 'TrialExpired' })
  } else if (to.path === '/' || to.path === '/login' || !to.matched.length) {
    next({ name: 'DataLibrary' })
  } else if (to.name.toLowerCase().includes('admin')) {
    if (store.getters['auth/isUserSuperAdmin']) {
      store.commit('setWorkspaceId', 1) // Workspace with ID 1 needed for admin page
      next()
    } else next({ name: 'DataLibrary' })
  } else {
    const workspaceId = parseInt(to.params.workspaceId || localStorage.getItem('workspaceId'))
    const projectId = parseInt(to.params.projectId)

    if (workspaceId == -1 && projectId == -1) next({ name: 'DataLibrary' })
    else {
      store.commit('setWorkspaceId', workspaceId)

      if (projectId) store.commit('setProjectId', projectId)

      const folderId = parseInt(to.params.folderId)
      store.commit('setFolderId', folderId || null)

      next()
    }
  }
})

router.afterEach(() => {
  document.body.style.cursor = 'default'
})

export default router
