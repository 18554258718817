// Constants
import { DATA_TYPES_MAP, REQUESTS_STATUS } from '@/constants'

export function transformIntegrationInfo({ data }) {
  return {
    authUrl: data.spec.AUTH_URL,
    scope: data.spec.scope,
    fields: data.spec.fields,
    type: data.spec.type
  }
}

export function transformConnectionMetricsInfo(data) {
  const attributes = data.ds_config_spec?.ux.attributes

  return {
    options: attributes?.datapull_options?.items.map((option) => ({
      ...option,
      items: Object.values(attributes).find((a) => a?.hidden_field.includes(option.value))?.items,
      key: Object.keys(attributes).find((key) => attributes[key]?.hidden_field.includes(option.value))
    })),
    tables: attributes?.tables?.items,
    fileList: (() => {
      const fileList = attributes?.file_list?.items || attributes?.documents?.items || []
      if (fileList[0]?.id == '.') {
        fileList[0].isDefaultExpanded = true
        fileList[0].label = './'
        return fileList
      }
      return fileList.map((item) => ({ ...item, selected: false }))
    })(),
    folderList: (attributes?.folder_list?.items || []).map((item) => ({ ...item, selected: false })),
    message: data.message,
    isValid: data.is_valid
  }
}

export function transformTableData(data) {
  const tableData = data?.data_sample || data
  if (data.status == REQUESTS_STATUS.ERROR) {
    return { columnDefs: [], rows: [] }
  }
  return {
    columnDefs:
      tableData.metadata?.map((c) => ({
        field: c.internal_name,
        headerName: c.display_name,
        datatype: DATA_TYPES_MAP[c.type]
      })) || [],
    rows:
      tableData.data?.data ||
      tableData.data ||
      (tableData.rows || tableData.data_rows)?.map((r) => {
        let row = {}
        tableData.metadata.forEach((c, index) => (row[c.internal_name] = r[index]))
        return row
      }) ||
      []
  }
}

export function transformConnectionInfo(data) {
  return {
    queryString: data.query_string,
    onRefreshAction: data.on_refresh_action,
    uniqueSequenceColumnName: data.unique_sequence_column?.display_name,
    identityKey: data.identity_key,
    datasetId: data.datasource_id,
    profile: data.profile,
    configKey: data.config_key,
    datapullType: data.data_pull_file,
    sheetName: data.sheet_name,
    folderList: data.folder_list,
    fileList: data.file_list,
    namePattern: data.name_pattern
  }
}
