import Vue from 'vue'
import { DATA_TYPES_MAP, DATE_GRANULARITY_ORDER, EXPLORE_CARD_TYPES, SORT_TYPES } from '@/constants'
import { getConditionFromSelectedValues, isNullCondition } from './explore-section.utils'

const getInitialState = () => ({
  configs: [
    /*
      {
        colId: 'columnId',
        renderType: 'chart',
        aggregation: {
          type: 'COUNT',
          targetColId: null,
          format: { separator: true }
        },
        granularity: '',
        sortBy: [['unformatted', 'DESC']]
      }
      */
  ],
  filterCondition: null,
  mode: 'dataEditor',
  // this is to make sure that there can be only one set of selected values in the entire explore section
  selectedValues: []
})

const getters = {
  isEmpty: (state) => !state.configs.length,
  configs: (state) => state.configs,
  exploreCardColumnIds: (state) => state.configs.map((config) => config.colId),
  mode: (state) => state.mode,
  filterCondition: (state) => state.filterCondition
}

const mutations = {
  setConfigs: (state, newState) => (state.configs = newState),
  setFilterCondition: (state, filterCondition) => (state.filterCondition = filterCondition),
  setCardConfig: (state, { columnId, payload }) => {
    const index = state.configs.findIndex((c) => c.colId === columnId)
    const newConfigs = { ...state.configs[index], ...payload }
    Vue.set(state.configs, index, newConfigs)
  },
  setCardChartConfig: (state, { columnId, chartIndex, payload }) => {
    const index = state.configs.findIndex((c) => c.colId === columnId)
    const chart = state.configs[index].charts[chartIndex]
    Vue.set(state.configs[index].charts, chartIndex, { ...chart, ...payload })
  },
  setSelectedValues: (state, selectedValues) => (state.selectedValues = selectedValues),
  resetSelectedValues: (state) => (state.selectedValues = []),
  deleteChartsAfterIndex: (state, { columnId, chartIndex }) => {
    const index = state.configs.findIndex((c) => c.colId === columnId)
    state.configs[index].charts.splice(chartIndex + 1)
  },
  deleteConditionsAfterIndex: (state, { columnId }) => {
    const index = state.configs.findIndex((c) => c.colId === columnId)
    state.configs.map((c, i) => {
      if (i > index) {
        c.condition = null
        c.activeValues = []
        if (c?.charts?.length >= 1) {
          c.charts.splice(1)
          c.charts[0].condition = null
          c.charts[0].activeValues = []
        }
      }
      return c
    })
  },
  sortConfigs(state) {
    state.configs.sort((a, b) => {
      if (a.condition && b.condition) return 0
      if (a.condition && !b.condition) return -1
      if (!a.condition && b.condition) return 1
      return 0
    })
  },
  setMode(state, mode) {
    state.mode = mode
  }
}

const actions = {
  setExploreCardColumns({ state, commit }, { columns }) {
    const columnIds = new Set(columns.map(({ id }) => id))
    const existingConfigs = state.configs.filter(({ colId }) => columnIds.has(colId))

    const existingColumnIds = new Set(existingConfigs.map(({ colId }) => colId))
    const newColumns = columns.filter(({ id }) => !existingColumnIds.has(id))

    const newConfigs = newColumns.map(({ id, type }) => {
      let config = {
        colId: id,
        renderType: EXPLORE_CARD_TYPES.LIST,
        condition: null,
        aggregation: {
          type: 'COUNT',
          targetColId: null,
          format: { separator: true }
        },
        granularity: undefined,
        sortBy: undefined
      }
      if (type !== DATA_TYPES_MAP.TEXT) {
        config.renderType = EXPLORE_CARD_TYPES.CHART
        config.charts = [
          {
            queryIndex: 0,
            activeValues: [],
            level: 'AUTO'
          }
        ]
      } else {
        config.sortBy = [['unformatted', SORT_TYPES.DESCENDING]]
        config.activeValues = []
      }
      return config
    })
    const cardConfigs = [...existingConfigs, ...newConfigs]
    commit('setConfigs', cardConfigs)
    const filterCondition = getFilterConditionFromConfigs(cardConfigs)
    const existingFilterCondition = state.filterCondition
    if (filterCondition !== existingFilterCondition) {
      commit('setFilterCondition', filterCondition)
    }
  },
  setExploreCardFilters({ state, commit }, { column, selectedValues, queryIndex = 0 }) {
    const card = state.configs.find((card) => card.colId === column.id)
    const condition = getConditionFromSelectedValues(card, column, selectedValues, queryIndex)
    if (card.renderType === EXPLORE_CARD_TYPES.CHART) {
      let chartIndex = queryIndex
      commit('setCardChartConfig', {
        columnId: column.id,
        chartIndex: queryIndex,
        payload: {
          condition,
          activeValues: selectedValues
        }
      })
      if (!isNullCondition(condition, column.id) && selectedValues.length === 1) {
        const chart = card.charts[queryIndex]
        chartIndex = queryIndex + 1
        const newChart = getChartTemplate(chartIndex, column, chart.level)
        if (newChart)
          commit('setCardChartConfig', {
            columnId: column.id,
            chartIndex: chartIndex,
            payload: newChart
          })
      }
      commit('deleteChartsAfterIndex', {
        columnId: column.id,
        chartIndex
      })
    }
    const payload = {
      condition,
      activeValues: selectedValues
    }
    commit('setCardConfig', {
      columnId: column.id,
      payload
    })
    commit('deleteConditionsAfterIndex', {
      columnId: column.id
    })

    commit('sortConfigs')
    const filterCondition = getFilterConditionFromConfigs(state.configs)
    commit('setFilterCondition', filterCondition)
  },
  removeExploreCardFilters({ state, commit }, { column }) {
    const card = state.configs.find((card) => card.colId === column.id)
    const payload = {
      condition: null,
      activeValues: []
    }
    if (card.renderType === EXPLORE_CARD_TYPES.CHART) {
      payload.charts = [getChartTemplate(0, column)]
    }
    commit('setCardConfig', {
      columnId: column.id,
      payload: payload
    })
    commit('sortConfigs')
    const filterCondition = getFilterConditionFromConfigs(state.configs)
    commit('setFilterCondition', filterCondition)
  },
  removeExploreCardChart({ state, commit }, { columnId, queryIndex = 0 }) {
    const card = state.configs.find((card) => card.colId === columnId)
    commit('setCardChartConfig', {
      columnId,
      chartIndex: queryIndex,
      payload: {
        condition: null,
        activeValues: []
      }
    })
    commit('deleteChartsAfterIndex', {
      columnId: columnId,
      chartIndex: queryIndex
    })
    commit('setCardConfig', {
      columnId: columnId,
      payload: {
        condition: card.charts[queryIndex - 1]?.condition
      }
    })
    commit('sortConfigs')
    const filterCondition = getFilterConditionFromConfigs(state.configs)
    commit('setFilterCondition', filterCondition)
  },
  clearExploreCardFilters({ state, commit }) {
    state.configs.forEach((c) => {
      commit('setCardConfig', {
        columnId: c.colId,
        payload: {
          condition: null,
          activeValues: []
        }
      })
      if (c.renderType === EXPLORE_CARD_TYPES.CHART) {
        commit('setCardChartConfig', {
          columnId: c.colId,
          chartIndex: 0,
          payload: {
            condition: null,
            activeValues: []
          }
        })
        commit('deleteChartsAfterIndex', {
          columnId: c.colId,
          chartIndex: 0
        })
      }
    })
    commit('setFilterCondition', null)
  },
  resetExploreState({ commit }, { configs }) {
    // verify columns in explore section are still visible in data editor
    commit('setConfigs', configs)
    const filterCondition = getFilterConditionFromConfigs(configs)
    commit('setFilterCondition', filterCondition)
  }
}

function getChartTemplate(queryIndex, column, level = 'AUTO') {
  let nextLevel = 'AUTO'
  if (level !== 'AUTO') {
    nextLevel = getLevel(level, column)
    if (nextLevel === 'AUTO') return
  }

  return {
    queryIndex,
    activeValues: [],
    level: nextLevel
  }
}

function getLevel(level, column) {
  let nextLevel = 'AUTO'
  if (column.type == DATA_TYPES_MAP.NUMERIC) {
    nextLevel = level - 1
  } else if (column.type === DATA_TYPES_MAP.DATE) {
    const selfLevelIndex = DATE_GRANULARITY_ORDER.indexOf(level)
    if (selfLevelIndex < DATE_GRANULARITY_ORDER.length - 1) {
      nextLevel = DATE_GRANULARITY_ORDER[selfLevelIndex + 1]
    }
  }

  return nextLevel
}

function getFilterConditionFromConfigs(configs) {
  const filterCondition = configs.reduce((acc, card) => {
    if (acc === null) {
      return card.condition
    }
    if (card.condition) {
      return { AND: [acc, card.condition] }
    }
    return acc
  }, null)
  return filterCondition || undefined
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
