export default [
  {
    path: '/admin',
    name: 'Admin',
    redirect: { name: 'AdminWorkspaces' },
    component: () => import('./pages/admin-page.vue'),
    children: [
      {
        path: 'workspaces',
        name: 'AdminWorkspaces',
        component: () => import('./components/workspaces/admin-workspaces.vue')
      },
      {
        path: 'integrations',
        name: 'AdminIntegrations',
        component: () => import('./components/admin-integrations.vue')
      }
    ]
  }
]
