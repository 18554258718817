import store from '@/store'

export default [
  {
    path: '/workspaces/:workspaceId/',
    name: 'Workspace',
    component: () =>
      store.state.isInterfaceV1Active ? import('./workspace-page.vue') : import('./workspace-page-v2.vue')
  }
]
