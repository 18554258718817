export default [
  {
    path: '/',
    component: () => import('./pages/auth-layout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('./components/auth-login/auth-login.vue'),
        meta: { requiresGuest: true }
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('./components/auth-reset-password/auth-reset-password.vue'),
        meta: { requiresGuest: true }
      }
    ]
  },
  {
    path: '/',
    component: () => import('./pages/auth-mail-layout.vue'),
    children: [
      {
        path: 'reset-password-check-email',
        name: 'ResetPasswordCheckEmail',
        component: () =>
          import(
            './components/auth-reset-password/auth-reset-password-check-email/auth-reset-password-check-email.vue'
          ),
        meta: { requiresGuest: true }
      },
      {
        path: 'reset-password-confirmation/:token',
        name: 'ResetPasswordConfirmation',
        component: () =>
          import(
            './components/auth-reset-password/auth-reset-password-confirmation/auth-reset-password-confirmation.vue'
          ),
        meta: { requiresGuest: true }
      },
      {
        path: 'accept-invite/:token',
        name: 'AcceptInvite',
        component: () => import('./components/auth-accept-invite/auth-accept-invite.vue')
      }
    ]
  }
]
