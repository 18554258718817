import { invert } from 'lodash'
export const DATA_TYPES_MAP = {
  DATE: 'date',
  NUMERIC: 'number',
  TEXT: 'text'
}

export const INVERTED_DATA_TYPES_MAP = invert(DATA_TYPES_MAP)

export const DATE_FORMATS_UNIVERSAL = [
  {
    format: 'YYYY/MM/DD',
    strftime: '%Y/%m/%d'
  },
  {
    momentFormat: 'YYYY/MM/DD HH:mm:ss',
    format: 'YYYY/MM/DD HH24:MI:SS',
    strftime: '%Y/%m/%d %H:%M:%S'
  },
  {
    momentFormat: 'YYYY/MM/DD hh:mm:ss a',
    format: 'YYYY/MM/DD HH:MI:SS am',
    strftime: '%Y/%m/%d %I:%M:%S %p'
  },
  {
    format: 'YYYY-MM-DD',
    strftime: '%Y-%m-%d'
  },
  {
    momentFormat: 'YYYY-MM-DD HH:mm:ss',
    format: 'YYYY-MM-DD HH24:MI:SS',
    strftime: '%Y-%m-%d %H:%M:%S'
  },
  {
    momentFormat: 'YYYY-MM-DD hh:mm:ss a',
    format: 'YYYY-MM-DD HH:MI:SS am',
    strftime: '%Y-%m-%d %I:%M:%S %p'
  },
  {
    momentFormat: 'DD-MMM-YY',
    format: 'DD-Mon-YY',
    strftime: '%d-%b-%y'
  },
  {
    momentFormat: 'DD-MMM-YY HH:mm:ss',
    format: 'DD-Mon-YY HH24:MI:SS',
    strftime: '%d-%b-%y %H:%M:%S'
  },
  {
    momentFormat: 'DD-MMM-YY hh:mm:ss a',
    format: 'DD-Mon-YY HH:MI:SS am',
    strftime: '%d-%b-%y %I:%M:%S %p'
  },
  {
    momentFormat: 'DD-MMM-YYYY',
    format: 'DD-Mon-YYYY',
    strftime: '%d-%b-%Y'
  },
  {
    momentFormat: 'DD-MMM-YYYY HH:mm:ss',
    format: 'DD-Mon-YYYY HH24:MI:SS',
    strftime: '%d-%b-%Y %H:%M:%S'
  },
  {
    momentFormat: 'DD-MMM-YYYY hh:mm:ss a',
    format: 'DD-Mon-YYYY HH:MI:SS am',
    strftime: '%d-%b-%Y %I:%M:%S %p'
  },
  {
    momentFormat: 'MMMM DD, YYYY',
    format: 'Month DD, YYYY',
    strftime: '%B %d, %Y'
  },
  {
    momentFormat: 'dddd, MMMM DD, YYYY',
    format: 'Day, Month DD, YYYY',
    strftime: '%A, %B %d, %Y'
  },
  {
    momentFormat: 'dddd, MMMM DD, YYYY HH:mm:ss',
    format: 'Day, Month DD, YYYY HH24:MI:SS',
    strftime: '%A, %B %d, %Y %H:%M:%S'
  },
  {
    momentFormat: 'dddd, MMMM DD, YYYY hh:mm:ss a',
    format: 'Day, Month DD, YYYY HH:MI:SS am',
    strftime: '%A, %B %d, %Y %I:%M:%S %p'
  },
  {
    momentFormat: 'YYYY-MM-DD HH:mm:ss.SSS',
    format: 'YYYY-MM-DD HH24:MI:SS.US',
    strftime: '%Y-%m-%d %H:%M:%S.%f'
  }
]

export const DATE_FORMATS_US = [
  {
    format: 'MM-DD-YY',
    strftime: '%m-%d-%y'
  },
  {
    momentFormat: 'MM-DD-YY HH:mm:ss',
    format: 'MM-DD-YY HH24:MI:SS',
    strftime: '%m-%d-%y %H:%M:%S'
  },
  {
    momentFormat: 'MM-DD-YY hh:mm:ss a',
    format: 'MM-DD-YY HH:MI:SS am',
    strftime: '%m-%d-%y %I:%M:%S %p'
  },
  {
    format: 'MM-DD-YYYY',
    strftime: '%m-%d-%Y'
  },
  {
    momentFormat: 'MM-DD-YYYY HH:mm:ss',
    format: 'MM-DD-YYYY HH24:MI:SS',
    strftime: '%m-%d-%Y %H:%M:%S'
  },
  {
    momentFormat: 'MM-DD-YYYY hh:mm:ss a',
    format: 'MM-DD-YYYY HH:MI:SS am',
    strftime: '%m-%d-%Y %I:%M:%S %p'
  },
  {
    format: 'MM/DD/YY',
    strftime: '%m/%d/%y'
  },
  {
    momentFormat: 'MM/DD/YY HH:mm:ss',
    format: 'MM/DD/YY HH24:MI:SS',
    strftime: '%m/%d/%y %H:%M:%S'
  },
  {
    momentFormat: 'MM/DD/YY hh:mm:ss a',
    format: 'MM/DD/YY HH:MI:SS am',
    strftime: '%m/%d/%y %I:%M:%S %p'
  },
  {
    format: 'MM/DD/YYYY',
    strftime: '%m/%d/%Y'
  },
  {
    momentFormat: 'MM/DD/YYYY HH:mm:ss',
    format: 'MM/DD/YYYY HH24:MI:SS',
    strftime: '%m/%d/%Y %H:%M:%S'
  },
  {
    momentFormat: 'MM/DD/YYYY hh:mm:ss a',
    format: 'MM/DD/YYYY HH:MI:SS am',
    strftime: '%m/%d/%Y %I:%M:%S %p'
  }
]
export const DATE_FORMATS_UK = [
  {
    format: 'DD-MM-YY',
    strftime: '%d-%m-%y'
  },
  {
    momentFormat: 'DD-MM-YY HH:mm:ss',
    format: 'DD-MM-YY HH24:MI:SS',
    strftime: '%d-%m-%y %H:%M:%S'
  },
  {
    momentFormat: 'DD-MM-YY hh:mm:ss a',
    format: 'DD-MM-YY HH:MI:SS am',
    strftime: '%d-%m-%y %I:%M:%S %p'
  },
  {
    format: 'DD-MM-YYYY',
    strftime: '%d-%m-%Y'
  },
  {
    momentFormat: 'DD-MM-YYYY HH:mm:ss',
    format: 'DD-MM-YYYY HH24:MI:SS',
    strftime: '%d-%m-%Y %H:%M:%S'
  },
  {
    momentFormat: 'DD-MM-YYYY hh:mm:ss a',
    format: 'DD-MM-YYYY HH:MI:SS am',
    strftime: '%d-%m-%Y %I:%M:%S %p'
  },
  {
    format: 'DD/MM/YY',
    strftime: '%d/%m/%y'
  },
  {
    momentFormat: 'DD/MM/YY HH:mm:ss',
    format: 'DD/MM/YY HH24:MI:SS',
    strftime: '%d/%m/%y %H:%M:%S'
  },
  {
    momentFormat: 'DD/MM/YY hh:mm:ss a',
    format: 'DD/MM/YY HH:MI:SS am',
    strftime: '%d/%m/%y %I:%M:%S %p'
  },
  {
    format: 'DD/MM/YYYY',
    strftime: '%d/%m/%Y'
  },
  {
    momentFormat: 'DD/MM/YYYY HH:mm:ss',
    format: 'DD/MM/YYYY HH24:MI:SS',
    strftime: '%d/%m/%Y %H:%M:%S'
  },
  {
    momentFormat: 'DD/MM/YYYY hh:mm:ss a',
    format: 'DD/MM/YYYY HH:MI:SS am',
    strftime: '%d/%m/%Y %I:%M:%S %p'
  }
]

export const DATE_FORMATS_MAP = [...DATE_FORMATS_UNIVERSAL, ...DATE_FORMATS_US, ...DATE_FORMATS_UK]

export const NUMERIC_FORMAT = {
  shortForm: false,
  separator: false,
  percentage: false,
  currency: '',
  decimalPrecision: 0
}
