// Constants
import { STEP_SUBTYPES } from './steps'

export const RESOURCE_TYPES = {
  DATASET: 'dataset',
  FILE: 'file',
  FOLDER: 'folder',
  REPORT: 'report',
  VIEW: 'view',
  WEBHOOK: 'webhook'
}

export const RESOURCE_STATUS = {
  ACTION_NEEDED: 'action_needed',
  DELETED: 'deleted',
  DELETING: 'deleting',
  ERROR: 'error',
  HAS_UNSTRUCTURED_DATA: 'has_unstructured_data',
  NEED_ACTION: 'need_action',
  OUTDATED: 'outdated',
  PROCESSED: 'processed',
  PROCESSING: 'processing',
  READY: 'ready',
  UNPROCESSED: 'unprocessed'
}

export const RESOURCE_DRAFT_STATUS = {
  CLEAN: 'clean',
  DIRTY: 'dirty',
  OFF: 'off'
}

export const RESOURCE_PIPELINE_STATUS = {
  ERROR: 'error',
  READY: 'ready',
  RUNNING: 'running',
  SUBMITTED: 'submitted'
}

export const RESOURCE_DEPENDENCIES = {
  ...[
    STEP_SUBTYPES.BRANCH_OUT,
    STEP_SUBTYPES.CROSSTAB,
    STEP_SUBTYPES.EXPORT,
    STEP_SUBTYPES.JOIN,
    STEP_SUBTYPES.LIVE_LINK,
    STEP_SUBTYPES.LOOKUP,
    STEP_SUBTYPES.PUBLISH
  ].reduce((o, k) => ({ ...o, [k]: k }), {}),
  VIEW: 'DATAVIEW'
}

export const IN_OUT_RESOURCE_DEPENDENCIES = [
  STEP_SUBTYPES.BRANCH_OUT,
  STEP_SUBTYPES.CROSSTAB,
  STEP_SUBTYPES.EXPORT,
  STEP_SUBTYPES.JOIN,
  STEP_SUBTYPES.LIVE_LINK,
  STEP_SUBTYPES.LOOKUP,
  STEP_SUBTYPES.PUBLISH
].map((k) => RESOURCE_DEPENDENCIES[k])

export const DATAFLOW_STATUS_NON_SYNC_TASKS_RESOURCE_DEPENDENCIES = {
  in: ['BRANCH_OUT', 'CROSSTAB'].map((k) => RESOURCE_DEPENDENCIES[k]),
  out: ['BRANCH_OUT', 'JOIN', 'LOOKUP', 'VIEW', 'EXPORT', 'LIVE_LINK', 'PUBLISH'].map((k) => RESOURCE_DEPENDENCIES[k])
}

export const DATASET_NAME_MAX_LENGTH = 300
export const COLUMN_NAME_MAX_LENGTH = 300

export const FOLDER_NAME_MAX_LENGTH = 100

export const RESOURCE_SOURCE_TYPE = {
  ABSTRACT: 'abstract',
  APPEND: 'append',
  CLOUD: 'cloud',
  FILE: 'file',
  SKETCH: 'sketch'
}

export const DATASET_UPDATE_METHOD = {
  APPEND: 'APPEND_TO_DS',
  REPLACE: 'REPLACE_IN_DS'
}
