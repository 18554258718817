<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-restrictions-wrapper-modal-buy-storage"
    :title="$t('settings.workspace.plan.restricted.storage_full')"
    width="450"
  >
    <span>{{ $t('settings.workspace.plan.restricted.storage_full_buy_more_description') }}</span>

    <template #actions>
      <mm-button :label="$t('global.dictionary.not_now')" objective="tertiary" @click="computedIsModalOpen = false" />
      <mm-button class="m-l-3" :label="$t('settings.workspace.storage.title')" @click="openSettings" />
    </template>
  </mm-modal>
</template>

<script>
export default {
  name: 'restrictions-wrapper-modal-buy-storage',
  props: {
    value: Boolean
  },
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    openSettings() {
      this.computedIsModalOpen = false

      const routeData = this.$router.resolve({
        name: 'SettingsWorkspace',
        params: this.$store.state,
        query: { dataStorage: true }
      })
      if (this.$store.state.isInterfaceV1Active) window.open(routeData.href, 'Mammoth_Settings')
      else window.location.href = routeData.href
    }
  }
}
</script>
