import { STEP_SUBTYPES, STEP_TYPES } from './steps'

export const LINK_BOOK_DEMO = 'https://mammoth.io/bookdemo/'

export const COLLABORATION_BLOG = 'https://docs.mammoth.io/content/howto/collaborate_in_mammoth.html'

export const STATUS_URL = 'https://status.mammoth.io'

export const DOCS_URL = 'https://docs.mammoth.io/'

export const LATEST_BLOG = {
  link: 'https://medium.com/mammoth-analytics/analytics-is-broken-its-time-for-a-change-eb35a039c9e1',
  name: "Analytics is Broken. It's Time for a Change"
}

export const DATE_FORMAT_GUIDE_LINK =
  'https://docs.mammoth.io/content/feature_guide/tasks/convert.columns.html?highlight=date%20format#format-guide-text-numeric-to-date'

export const FUNCTION_DOC_LINKS = {
  [STEP_SUBTYPES.ADD_COLUMN]: 'https://docs.mammoth.io/content/feature_guide/tasks/new.column.html',
  [STEP_SUBTYPES.BRANCH_OUT]: 'https://docs.mammoth.io/content/feature_guide/tasks/branch.out.to.dataset',
  [STEP_SUBTYPES.BULK_COPY]: 'https://docs.mammoth.io/content/feature_guide/tasks/copy.column.values.html',
  [STEP_SUBTYPES.BULK_REPLACE]: 'https://docs.mammoth.io/content/feature_guide/tasks/bulkreplace.html',
  [STEP_SUBTYPES.COMBINE]: 'https://docs.mammoth.io/content/feature_guide/tasks/combine.column.html',
  [STEP_SUBTYPES.CONCATENATE]: 'https://docs.mammoth.io/content/feature_guide/tasks/concatenate.values.html',
  [STEP_SUBTYPES.CONVERT]: 'https://docs.mammoth.io/content/feature_guide/tasks/convert.columns.html',
  [STEP_SUBTYPES.CROSSTAB]: 'https://docs.mammoth.io/content/feature_guide/tasks/crosstab.html',
  [STEP_SUBTYPES.DATE_DIFF]: 'https://docs.mammoth.io/content/feature_guide/tasks/date.diff.html',
  [STEP_SUBTYPES.DELETE]: 'https://docs.mammoth.io/content/feature_guide/tasks/remove.column.html',
  [STEP_SUBTYPES.DISCARD_DUPLICATES]: 'https://docs.mammoth.io/content/feature_guide/tasks/remove.duplicates.html',
  [STEP_SUBTYPES.EXTRACT_DATE]: 'https://docs.mammoth.io/content/feature_guide/tasks/date.extract.html',
  [STEP_SUBTYPES.FILL]: 'https://docs.mammoth.io/content/feature_guide/tasks/fill.missing.values.html',
  [STEP_SUBTYPES.INCREMENT_DATE]: 'https://docs.mammoth.io/content/feature_guide/tasks/increment.decrement.date.html',
  [STEP_SUBTYPES.JOIN]: 'https://docs.mammoth.io/content/feature_guide/tasks/join.views',
  [STEP_SUBTYPES.JSON_HANDLE]: 'https://docs.mammoth.io/content/feature_guide/tasks/json.extract.html',
  [STEP_SUBTYPES.LIMIT]: 'https://docs.mammoth.io/content/feature_guide/tasks/showtopbottom.html',
  [STEP_SUBTYPES.LOOKUP]: 'https://docs.mammoth.io/content/feature_guide/tasks/lookup.html',
  [STEP_SUBTYPES.MATH]: 'https://docs.mammoth.io/content/feature_guide/tasks/math.rule.html',
  [STEP_SUBTYPES.OBTAIN_LARGE_OR_SMALL]: 'https://docs.mammoth.io/content/feature_guide/tasks/small.large.html',
  [STEP_SUBTYPES.PIVOT]: 'https://docs.mammoth.io/content/feature_guide/tasks/group.html',
  [STEP_SUBTYPES.REPLACE]: 'https://docs.mammoth.io/content/feature_guide/tasks/findandreplace.html',
  [STEP_SUBTYPES.SELECT]: 'https://docs.mammoth.io/content/feature_guide/tasks/applyfilter.html',
  [STEP_SUBTYPES.SET]: 'https://docs.mammoth.io/content/feature_guide/tasks/labels.html',
  [STEP_SUBTYPES.SPLIT]: 'https://docs.mammoth.io/content/feature_guide/tasks/split.column.html',
  [STEP_SUBTYPES.SUBSTRING]: 'https://docs.mammoth.io/content/feature_guide/tasks/text.extract',
  [STEP_SUBTYPES.TEXT_TRANSFORM]: 'https://docs.mammoth.io/content/feature_guide/tasks/text.transform.html',
  [STEP_SUBTYPES.UNNEST]: 'https://docs.mammoth.io/content/feature_guide/tasks/columns.to.rows.html',
  [STEP_SUBTYPES.WINDOW]: 'https://docs.mammoth.io/content/feature_guide/tasks/window.function.html',
  [STEP_TYPES.REPORT]: 'https://docs.mammoth.io/content/feature_guide/publish_report'
}

export const DOC_TAGS_FOR_WINDOW_FUNCTIONS = {
  AVG: 'average',
  COUNT: 'count',
  CUMULATIVE_PERCENT_RANK: 'cumulative-percent-rank',
  DENSE_RANK: 'dense-rank',
  FIRST_VALUE: 'firs-value',
  LAG: 'lag',
  LAST_VALUE: 'last-value',
  LEAD: 'lead',
  MAX: 'maximum',
  MIN: 'minimum',
  NTILE: 'ntile',
  NTH_VALUE: 'nth-value',
  PERCENT_RANK: 'percent-rank',
  RANK: 'rank',
  ROW_NUMBER: 'row-number',
  STDDEV: 'standard-deviation',
  SUM: 'sum',
  VARIANCE: 'variance'
}
