import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './plugins/store'
import i18n from './plugins/i18n'
import { appendChargebeeScript } from './plugins/chargebee'
import userEvents from './plugins/userEvents'
import keycloak, { KEYCLOAK_INIT_OPTIONS } from './plugins/keycloak'
import vuetify from './plugins/vuetify'
import MMStorybook from '@mammoth_developer/mm-storybook'
import '@/modules/auth/scripts/multi-tabs.auth.js'
import 'vuetify/dist/vuetify.min.css'
import '@mammoth_developer/mm-storybook/dist/style.css'
import './plugins/moment'
import './styles/app.scss'
import './styles/v1.scss'
import * as Sentry from '@sentry/vue'
import { ENV_KEY } from '@/constants'

Vue.config.productionTip = false

const mmStorybookOptions = {}
Vue.use(MMStorybook, mmStorybookOptions)
Vue.use(mmStorybookOptions.mmToasts, { vuetify: vuetify })
Vue.use(mmStorybookOptions.mmBanners)
Vue.use(userEvents)
appendChargebeeScript()

// Since vue app it not initialized yet here, we can't get current route from router object
// So we find current route using window.location to verify if we need to skip auth
let currentRoute
for (const route of router.options.routes) {
  const parentRoutePath = route.path.split('/')[1]
  if (parentRoutePath && window.location.href.includes(parentRoutePath) && !route.children?.length) currentRoute = route
  else if (route.children?.length)
    currentRoute = route.children.find((r) => window.location.href.includes(`${r.path.split('/')[0]}/`))
  if (currentRoute) break
}
const skipAuth = !!currentRoute?.meta?.requiresGuest

Vue.config.errorHandler = (err) => {
  //Sentry.captureException catches and sends error data to sentry and
  //prevents error to be logged in browser console so we have to manually log it
  console.error(err)
  Sentry.captureException(err)
}

Sentry.init({
  app: Vue,
  dsn: ENV_KEY.VITE_APP_SENTRY_URL,
  environment: ENV_KEY.VITE_APP_MODE,
  release: ENV_KEY.VITE_APP_GIT_RELEASE_TAG,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/*.mammoth\.io\/*/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

const getVueAppInstance = () =>
  new Vue({
    vuetify: vuetify,
    i18n: i18n,
    router,
    store,
    render: (h) => h(App, { props: { keycloak } })
  }).$mount('#app')

const vueApp = { instance: null }
if (skipAuth) vueApp.instance = getVueAppInstance()
else
  keycloak.init(KEYCLOAK_INIT_OPTIONS).then((auth) => {
    if (auth) vueApp.instance = getVueAppInstance()
    else keycloak.login()
  })

export default vueApp
