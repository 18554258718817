// API
import API from '@/api'
import globalApi from '@/api/global.api'

// Transforms
import {
  transformAllResources,
  transformNewView,
  transformResources,
  transformPipelineChanges,
  transformAppliedPipelineChanges,
  transformDatasetFileInfo,
  transformDatasetUnstructuredData
} from './resources.transform.js'

// Constants
import { RESOURCE_TYPES } from '@/constants'

const apiGenerator = (path, config) => async () => {
  const { data } = await API.get(path, config)
  return { data }
}

export default {
  getResources: async (lastCheckedUntil = 0, projectId) =>
    transformAllResources(
      await apiGenerator(
        `/resources?last_checked_until=${lastCheckedUntil}${projectId > 0 ? `&project_id=${projectId}` : ''}`,
        { customErrorHandling: true }
      )().catch((error) => error)
    ),
  refreshWebhook: (webhookId) => API.post(`webhooks/${webhookId}`),
  updateWebhookMode: async (webhookId, mode) => {
    return await API.patch(`webhooks/${webhookId}`, { patch: [{ op: 'replace', path: 'mode', value: mode }] })
  },
  updateWebhookSecureStatus: async (webhookId, status) => {
    return await API.patch(`webhooks/${webhookId}`, { patch: [{ op: 'replace', path: 'is_secure', value: status }] })
  },
  retrieveDatasetLatestData: async (datasetId) =>
    API.patch(`datasets/${datasetId}?validation_only=false`, {
      patch: [{ op: 'replace', path: 'data', value: 'refresh' }]
    }),
  updateDatapullStatus: async (datasetId, status) =>
    API.patch(`datasets/${datasetId}?validation_only=false`, {
      patch: [
        {
          op: 'replace',
          path: 'schedule',
          value: 'pull_cloud_data',
          notification_id: null,
          schedule_state: status ? 'resume' : 'pause'
        }
      ]
    }),
  rename: async (id, resourceType, newName) => {
    const resourceTypeMap = {
      [RESOURCE_TYPES.DATASET]: 'datasets',
      [RESOURCE_TYPES.FOLDER]: 'labels',
      [RESOURCE_TYPES.VIEW]: 'dataviews'
    }
    const { data } = await API.patch(
      `${resourceTypeMap[resourceType]}/${id}`,
      { patch: [{ op: 'replace', path: 'name', value: newName }] },
      { customErrorHandling: true }
    ).catch((error) => error)
    return data
  },
  remove: async (id, resourceType) => {
    const resourceTypeMap = {
      [RESOURCE_TYPES.DATASET]: () => API.delete(`/datasets/${id}`),
      [RESOURCE_TYPES.FILE]: () => API.delete(`/files/${id}`),
      [RESOURCE_TYPES.FOLDER]: () => API.delete(`/labels/${id}?check_for_dependency=true`),
      [RESOURCE_TYPES.VIEW]: () => API.post(`/dataviews/${id}/safe-delete-request`, {})
    }
    const { data } = await resourceTypeMap[resourceType]()
    if (data.future_id) return await globalApi.getFutureRequest(data.future_id)
    return data
  },
  extractFileSheets: async (fileId, sheets, deleteFile) => {
    const { data } = await API.patch(`files/${fileId}`, {
      patch: [{ op: 'replace', path: 'extract_sheets', value: sheets, deleteFile }]
    })
    return await globalApi.getFutureRequest(data.future_id)
  },
  validateFilePassword: async (fileId, password) => {
    const { data } = await API.patch(`files/${fileId}`, {
      patch: [{ op: 'replace', path: 'validate_password', value: password }]
    })
    return await globalApi.getFutureRequest(data.future_id)
  },
  addNewView: async (datasetId, params = null) =>
    transformNewView(
      await API.post(`datasets/${datasetId}/dataviews`, params, { customErrorHandling: true }).catch((error) => error)
    ),
  duplicateView: async (viewId, datasetId) =>
    transformNewView(
      await API.post(
        `datasets/${datasetId}/dataviews`,
        { id: datasetId, clone_config_from: viewId },
        { customErrorHandling: true }
      ).catch((error) => error)
    ),
  duplicateDataset: async (datasetId, isDuplicateViewsEnabled) =>
    await API.post('datasets', { clone_existing: datasetId, clone_dataviews: isDuplicateViewsEnabled }),
  getReportResources: async () => transformResources(await apiGenerator(`/reports`)()),
  getPendingPipelineChanges: async (projectId) => {
    const { data } = await API.get(`pipeline_changes?project_id=${projectId}`)
    return transformPipelineChanges(await globalApi.getFutureRequest(data.future_id))
  },
  applyPendingPipelineChanges: async (viewsIds) => {
    const { data } = await API.post('pipeline_changes', { dataview_ids: viewsIds.map((viewId) => Number(viewId)) })
    return transformAppliedPipelineChanges(await globalApi.getFutureRequest(data.future_id))
  },
  clearDatasetPreview: async (datasetId) => {
    const { data } = await API.post(`datasets/${datasetId}/csvfile`, { cancel: true })
    return transformDatasetFileInfo(data)
  },
  getDatasetFileInfo: async (datasetId) => {
    const { data } = await API.get(`datasets/${datasetId}/csvfile`)
    return transformDatasetFileInfo(data)
  },
  setDatasetFileStructure: async (datasetId, payload) => {
    const { data } = await API.post(`datasets/${datasetId}/csvfile`, payload)
    return await globalApi.getFutureRequest(data.future_id)
  },
  getDatasetUnstructuredData: async (datasetId) => {
    const { data } = await API.get(`datasets/${datasetId}/unstructured_data`)
    return transformDatasetUnstructuredData(data)
  },
  updateFileSkippedRows: async (datasetId, rows, operation) => {
    const { data } = await API.patch(`datasets/${datasetId}/unstructured_data`, {
      patch: [
        {
          op: operation,
          path: 'unstructured_rows',
          value: {
            batch_id: rows[0].batchId,
            line_num: rows.map((r) => r.lineNum),
            data: rows.map((r) => ({ line_num: r.lineNum, line: r.line }))
          }
        }
      ]
    })
    if (data.future_id) return await globalApi.getFutureRequest(data.future_id)
    return data
  }
}
