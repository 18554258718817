<template>
  <mm-modal
    v-model="computedIsModalOpen"
    content-class="mm-data-addition-third-party-connection-new-sftp-ssh"
    :title="computedModalTitle"
    :persistent="isLoading"
    width="575"
    closable
  >
    <div v-if="!sshKey">
      <mm-form v-model="isFormValid" ref="form" :key="`form_${formKey}`">
        <mm-row no-gutters>
          <mm-col v-for="(field, key) in computedFields" :key="`field_${field.props.title}`" cols="12" md="6">
            <mm-text-input
              v-model="formData[key]"
              v-bind="field.props"
              class="p-x-1"
              :disabled="selectedConnection && key != 'name'"
              @enterKeyPressed="submit"
              @input="errorMessage = ''"
            />
          </mm-col>
        </mm-row>
      </mm-form>

      <mm-tooltip v-if="errorMessage" :label="errorMessage" wrapper-class="error-message-wrapper">
        <div class="d-flex">
          <mm-icon name="error" color="dest800" class="m-r-2" />
          <span class="mm-text--caption-regular error-message">{{ errorMessage }}</span>
        </div>
      </mm-tooltip>

      <div v-if="selectedConnection">
        <div class="d-flex">
          <span>{{ $t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.generated') }}</span>
          <mm-button class="m-l-auto" icon="download" objective="tertiary" size="small" @click="download" />
          <mm-button icon="copy" objective="tertiary" size="small" @click="copy" />
        </div>
        <div class="ssh-key mm-rounded-borders p-a-3">
          <span>{{ computedSshKey }}</span>
        </div>
      </div>
    </div>

    <div v-else>
      <mm-callout
        class="m-t-7 m-b-5"
        :message="$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.copy_info')"
      />
      <mm-button
        class="d-flex m-l-auto m-b-4"
        :label="$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.download')"
        icon="download"
        objective="tertiary"
        @click="download"
      />
      <div class="ssh-key mm-rounded-borders p-a-3">
        <span>{{ computedSshKey }}</span>
      </div>
    </div>

    <template #actions>
      <mm-button v-if="sshKey" :label="$t(copyBtnLabel)" icon="copy" @click="copy" />
      <div v-else>
        <mm-button
          :label="$t('global.dictionary.cancel')"
          :disabled="isLoading"
          objective="tertiary"
          @click="computedIsModalOpen = false"
        />
        <mm-button
          class="m-l-3"
          :label="
            $t(
              selectedConnection
                ? 'global.dictionary.submit'
                : 'data_library.data_addition.third_party.new_connection_sftp.ssh_key.generate'
            )
          "
          :loading="isLoading"
          :disabled="!isFormValid"
          @click="submit"
        />
      </div>
    </template>
  </mm-modal>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

// Utils
import { saveFile } from '@/utils/files'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'data-library-data-addition-third-party-connection-new-sftp-ssh',
  props: {
    value: Boolean,
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    selectedConnection: Object
  },
  data: () => ({
    formData: {},
    isFormValid: false,
    isLoading: false,
    errorMessage: '',
    sshKey: '',
    copyBtnLabel: '',
    formKey: 0
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    computedModalTitle() {
      if (this.selectedConnection) return this.$t('data_library.data_addition.third_party.edit_connection.title')
      if (this.sshKey) return this.$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.title')
      return this.$t('data_library.data_addition.third_party.new_connection.title')
    },
    computedFields() {
      let connectorsFields = { ...this.connector.connection.fields }
      delete connectorsFields.password
      return connectorsFields
    },
    computedSshKey() {
      return this.sshKey || this.selectedConnection.fields?.public_key
    }
  },
  watch: {
    computedIsModalOpen(val) {
      if (val) {
        this.formKey++
        this.sshKey = ''
        this.errorMessage = ''
        this.copyBtnLabel = 'data_library.data_addition.third_party.new_connection_sftp.ssh_key.copy'

        // If there is a selected connection to edit, set formData to its fields
        if (this.selectedConnection) this.formData = { ...this.selectedConnection.fields }
        else {
          // Initialize formData object and set each field value, in case there is a default value
          this.formData = Object.keys(this.computedFields).reduce(
            (o, k) => ({ ...o, [k]: this.computedFields[k].props?.value }),
            {}
          )
        }
      } else this.$emit('close')
    }
  },
  methods: {
    async submit() {
      this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_SUBMIT)

      const formErrors = this.$refs.form.getFormErrors()
      if (formErrors.length) return

      this.errorMessage = ''
      this.isLoading = true

      const formData = {
        ...this.formData,
        ssh_key_authentication: true,
        generate_ssh_keys: true,
        project_id: this.$store.state.projectId
      }

      let connectionResponse
      // Edit existing connection
      if (this.selectedConnection)
        connectionResponse = await dataLibraryApi.editConnection(
          this.connector.hash,
          this.selectedConnection.value,
          formData
        )
      // Create new connection
      else connectionResponse = await dataLibraryApi.setNewConnection(this.connector.hash, formData)

      const errorMessage = connectionResponse.message || connectionResponse.reason
      if (errorMessage) this.errorMessage = errorMessage
      else {
        this.sshKey = connectionResponse.identity.public_key
        this.$emit('success')

        if (this.selectedConnection) this.computedIsModalOpen = false
      }

      this.isLoading = false
    },
    async copy() {
      await navigator.clipboard.writeText(this.computedSshKey)
      this.copyBtnLabel = 'global.dictionary.copied'
      this.$toast.show({
        content: this.$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.copy_success'),
        status: 'success'
      })
    },
    download() {
      saveFile(this.computedSshKey, `${(this.formData.name || this.formData.domain).replace('.', '_')}.pub`)
    }
  }
}
</script>

<style lang="scss">
.mm-data-addition-third-party-connection-new-sftp-ssh {
  .mm-text-input input {
    height: 16px;
  }

  .error-message-wrapper {
    width: 100%;

    .mm-icon {
      min-width: 16px;
    }

    .error-message {
      color: var(--mm-color--dest800);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ssh-key {
    background: var(--mm-color--n30);
    color: var(--mm-color--p800);
    max-height: 100px;
    overflow: auto;

    span {
      overflow-wrap: break-word;
    }
  }
}
</style>
