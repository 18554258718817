export const ERROR_CODE = {
  COLUMN_MISSING: 7004,
  COLUMN_NAME_REPEATED: 35,
  COLUMN_TYPE_MISMATCH: 7003,
  DEPENDENCIES_DETECTED: 7010,
  DEPENDENCY_PIPELINE_IN_ERROR: 7005,
  DERIVATIVE_BEING_CREATED: 1503,
  INVALID_WORKSPACE_ID: 6,
  PROJECT_DOES_NOT_EXIST: 6600,
  REFERENCE_ERROR: 7001,
  USER_LACKS_PERMISSION: 70
}
