// Dependencies
import axios from 'axios'

//Plugins
import '@/plugins/store'
import keycloak from '@/plugins/keycloak'
import router from '@/plugins/router'

// Constants
import { ENV_KEY } from '@/constants'

export const baseURL = ENV_KEY.VITE_APP_API_URL || `${window.location.origin}/api/v1`

// Axios instance
const apiInstance = axios.create({
  baseURL,
  timeout: 30000,
  withCredentials: true
})

// Response interceptors
apiInstance.interceptors.response.use(
  // For success responses
  async (response) => {
    if (!response.fromCache && response.config.cacheable) {
      const cacheValue = {
        cacheData: response.data,
        cacheAge: response.config.cacheAge,
        cacheUpdateAt: Date.now()
      }
      localStorage.setItem(response.config.cacheKey, JSON.stringify(cacheValue))
    }
    return response
  },
  // For error responses
  async ({ response, config }) => {
    // customErrorHandling defines if a request has a custom way of handling error messages
    // e.g., output error message on text input
    if (config?.customErrorHandling) return Promise.reject(response || { error: 'global.api.network_error' })
  }
)

// Request interceptors
apiInstance.interceptors.request.use(
  // For success
  async (config) => {
    if (router.currentRoute?.meta?.requiresGuest) return config

    await keycloak.updateToken(30)
    // eslint-disable-next-line require-atomic-updates
    config.headers.Authorization = `bearer ${keycloak.token}`
    if (config.cacheable) {
      const cachedResponse = JSON.parse(localStorage.getItem(config.cacheKey))
      if (cachedResponse) {
        // Checking if the cached value is still valid, otherwise we remove it
        if (Date.now() - cachedResponse.cacheUpdateAt < cachedResponse.cacheAge && !config.invalidateCache) {
          // Read about adapter here: https://mammoth.atlassian.net/l/cp/LB8q6xHD
          config.adapter = () => {
            return Promise.resolve({
              config,
              data: cachedResponse.cacheData,
              fromCache: true
            })
          }
        } else {
          localStorage.removeItem(config.cacheKey)
        }
      }
    }
    return config
  },
  // For error
  (error) => Promise.reject(error)
)

export default apiInstance
