import store from '@/store'

export default [
  {
    path: '/settings',
    name: 'Settings',
    redirect: { name: 'SettingsProfile' },
    component: () =>
      store.state.isInterfaceV1Active ? import('./pages/settings-page.vue') : import('./pages/settings-page-v2.vue'),
    children: [
      {
        path: 'profile',
        name: 'SettingsProfile',
        component: () => import('./components/settings-profile/settings-profile.vue')
      },
      {
        path: 'security-and-privacy',
        name: 'SettingsSecurityPrivacy',
        component: () => import('./components/settings-security-privacy/settings-security-privacy.vue')
      },
      {
        path: 'workspace/:workspaceId',
        name: 'SettingsWorkspace',
        component: () => import('./components/settings-workspace/settings-workspace.vue')
      }
    ]
  }
]
