export const SMS_STATUS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  TRIAL: 'in_trial'
}

export const ENTERPRISE_PLAN = {
  enterprise: true,
  startingUsersQuantity: 30,
  areNewUsersAllowed: true,
  startingStorage: 10000,
  isAdditionalStorageAllowed: true,
  maxStorage: 1000000,
  processingCapacity: 'powerhouse',
  isCSVExcelImportEnabled: true,
  isImportPublicDatasetsEnabled: true,
  startingConnectorQuantity: 30,
  minimumPeriodicityForDataPulls: 'minutely',
  areAdditionalConnectorsAllowed: true,
  areAllTransformFeaturesEnabled: true,
  isCSVExcelExportEnabled: true,
  isCreateDbEnabled: true,
  isLiveLinkEnabled: true,
  isPrivateViewEnabled: true,
  arePrivateViewersPerViewUnlimited: true,
  isPublicViewEnabled: true,
  isVersionControlEnabled: true,
  areAuditReportsEnabled: true,
  isDataAccessControlEnabled: true,
  hasCustomerSuccessManager: true,
  isWebhookEnabled: true
}

export const BILLING_TYPES = {
  MONTH: 'month',
  YEAR: 'year'
}

export const STORAGE_WARNING_PERCENTAGE = 80
