import { DATA_TYPES_MAP, ERROR_CODE } from '@/constants'

/**
 * The complete metadata
 * @typedef {Object} Metadata
 * @property {string} internal_name - column id
 * @property {string} display_name - column display name
 * @property {string} type - column type
 */

/**
 * The complete displayProperties
 * @typedef {Object} DisplayProperties
 * @property {Object.<string, number>} COLUMN_WIDTHS - column width
 * @property {Object.<string, number>} FORMAT_INFO - column format
 * @property {Object.<string, string>} COLUMN_NAMES - column name
 * @property {Object.<string, number>[]} EXPLORE_PANEL - Explore Config
 */

/**
 * The complete columnDef
 * @typedef {Object} columnDef
 * @property {string} id - column id
 * @property {string} name - column display name
 * @property {string} type - column type
 * @property {boolean} isVisible - Indicates whether the column is displayed or hidden
 * @property {boolean} isExploreCardOpen - Indicates whether the explore card is open for the column.
 * @property {boolean} isSelected - Indicates whether column is highlighted in the grid.
 */

/**
 * Description
 * @param {Metadata[]} metadata
 * @param {DisplayProperties} displayProperties
 * @returns {columnDef[]}
 */

export function getColumnDefs(metadata, displayProperties) {
  const columnNames = displayProperties?.COLUMN_NAMES || {}
  const hiddenColumnIds = displayProperties.HIDDEN_COLUMNS || []
  const columnWidths = displayProperties?.COLUMN_WIDTHS || {}
  const columnFormat = displayProperties?.FORMAT_INFO || {}
  const columnOrder = displayProperties?.COLUMN_ORDER || []

  let colFormat = {
    TEXT: () => ({
      // changeCase: UPPER | LOWER | TITLE
      // normalizeWhitespaces: boolean
    }),
    DATE: (format) => ({
      format: {
        date: {
          format: format?.date_format
        }
      }
    }),
    NUMERIC: (format) => ({
      format: {
        number: {
          shortForm: false,
          separator: format?.comma_separated || false,
          percentage: format?.is_percentage || false,
          currency: format?.currency_symbol || '',
          decimalPrecision: format?.decimal_spec || 0
        }
      }
    })
  }
  const columns = metadata.map(({ type, internal_name, display_name }) => ({
    id: internal_name,
    name: columnNames[internal_name] || display_name,
    type: DATA_TYPES_MAP[type],
    isVisible: !hiddenColumnIds.includes(internal_name),
    isExploreCardOpen: false,
    isSelected: false,
    displayProperties: {
      ...colFormat[type](columnFormat[internal_name]),
      width: columnWidths[internal_name] || 161,
      originalName: columnNames[internal_name] && columnNames[internal_name] !== display_name ? display_name : null
    }
  }))
  const orderedColumns = Object.values(columnOrder)
  if (!orderedColumns?.length) return columns
  const columnDefs = orderedColumns.map((columnId) => columns.find((column) => column.id === columnId)).filter((c) => c)

  const extraColumns = columns.filter((col) => !orderedColumns.includes(col.id))
  if (extraColumns.length) return [...columnDefs, ...extraColumns]
  return columnDefs
}
/**
 * Description
 * @param {Metadata[]} metadata
 * @param {DisplayProperties} displayProperties
 * @returns {columnDef[]}
 */
export function transformGridColumnsMetaData(metadata, display_properties) {
  const columnDefs = getColumnDefs(metadata, display_properties)
  return { columnDefs }
}

export function transformDisplayProperties(displayProperties) {
  return {
    sort: displayProperties.SORT,
    formatInfo: displayProperties.FORMAT_INFO
  }
}

export function transformTaskFailureResponse(data) {
  const response = {
    ...data,
    errorCode: data.ERROR_CODE,
    status: data.STATUS,
    additionalInfo: data.ADDITIONAL_INFO
  }
  if (response.errorCode === ERROR_CODE.COLUMN_NAME_REPEATED)
    response.additionalInfo = { name: data.ADDITIONAL_INFO.display_name }
  return response
}

export function transformTaskErrorResponse(data) {
  const response = {
    ...data,
    errorCode: data.error_code
  }
  return response
}

export function transformDerivatives(derivatives) {
  return derivatives.map((d) => ({
    id: d.id,
    category: d.category,
    displayProperties: d.display_properties,
    metricStatus: d.metric_status,
    param: d.param,
    referenceErrors: d.reference_errors?.reference_errors,
    status: d.status
  }))
}
