import { EXPORT_CONNECTORS_MAP } from './connectors'

export const NOTIFICATION_STATUS = {
  COMPLETED: 'completed',
  DISMISSED: 'dismissed',
  ERROR: 'error',
  PROCESSING: 'processing'
}

export const TOAST_SUBTYPES = [
  EXPORT_CONNECTORS_MAP.BIGQUERY.key,
  EXPORT_CONNECTORS_MAP.ELASTICSEARCH.key,
  EXPORT_CONNECTORS_MAP.MSSQL.key,
  EXPORT_CONNECTORS_MAP.MYSQL.key,
  EXPORT_CONNECTORS_MAP.POSTGRES.key,
  EXPORT_CONNECTORS_MAP.REDSHIFT.key,
  EXPORT_CONNECTORS_MAP.SFTP.key,
  'email',
  'internal_dataset',
  'publishdb',
  's3'
]
