//Constants
import { NOTIFICATION_STATUS, TOAST_SUBTYPES } from '@/constants'

const NOTIFICATION_ERRORS = {
  auth_failed: {
    expression: 'password authentication failed for user',
    getDetails: (msg) => ({
      errorKey: 'auth_failed',
      errorUser: msg.match(/"(.*?)"/)[0].slice(1, -1)
    })
  },
  dataset_not_present: {
    expression: 'Dataset no longer exists.',
    getDetails: (msg, notification) => ({
      errorKey: 'dataset_not_present',
      targetDatasetName: notification.details.data?.target_properties?.DS_NAME
    })
  },
  duplicate_category_name: {
    expression: 'psycopg2.errors.DuplicateObject',
    getDetails: () => ({ errorKey: 'duplicate_category_name' })
  },
  duplicate_rows_bigquery: {
    expression: 'Please make sure all the rows are unique when doing BigQuery Merge operation.',
    getDetails: () => ({ errorKey: 'duplicate_rows_bigquery' })
  },
  export_dataset_failed: {
    expression: '444799_558654',
    getDetails: (msg, notification) => ({
      errorKey: 'export_dataset_failed',
      targetDatasetName: notification.details.data.ds_name
    })
  },
  generic: { getDetails: () => ({ errorKey: 'generic' }) },
  generic_external: {
    expression: 'Oops! Something went wrong! Help us improve your experience by sending an error report.',
    getDetails: () => ({ errorKey: 'generic_external' })
  },
  identity_creation: {
    expression: 'Failed to create identity',
    getDetails: () => ({ errorKey: 'identity_creation' })
  },
  instance_not_present: {
    expression: 'has been deleted, or its row is otherwise not present',
    getDetails: (msg) => ({
      errorKey: 'instance_not_present',
      errorInstance: msg.match('Workspace at (.*)&gt;')?.[1] || msg.match('Instance at (.*)&gt;')?.[1]
    })
  },
  invalid_grant: {
    expression: 'invalid_grant',
    getDetails: () => ({ errorKey: 'invalid_grant' })
  },
  invalid_identity: {
    expression:
      'Could not deserialize key data. The data may be in an incorrect format, it may be encrypted with an unsupported algorithm, or it may be an unsupported key type (e.g. EC curves with explicit parameters).',
    getDetails: () => ({ errorKey: 'invalid_identity' })
  },
  mismatching_schemas: {
    expression: '142',
    getDetails: (msg, notification) => ({
      errorKey: 'mismatching_schemas',
      newDatasetName: notification.details.data.DATASOURCE_NAME,
      targetDatasetName: notification.details.data.DESTINATION_DATASOURCE_NAME
    })
  },
  missing_fields: {
    expression: 'Service workspace info was not in the expected format, missing fields',
    getDetails: (msg) => ({ errorKey: 'missing_fields', errorMissingFields: msg.match('missing fields (.*).')[1] })
  },
  no_matching_file: {
    expression: 'No matching file found for the given pattern',
    getDetails: () => ({ errorKey: 'no_matching_file' })
  },
  no_such_file: {
    expression: '[Errno 2] No such file',
    getDetails: (msg, notification) => ({
      errorKey: 'no_such_file',
      targetDatasetName: notification.details.data.ds_name
    })
  },
  not_existing_table: {
    expression: ' does not exist',
    getDetails: (msg) => ({ errorKey: 'not_existing_table', errorInstance: msg.split(' ')[1] })
  },
  storage_exceeded: {
    expression: 'Storage quota exceeded',
    getDetails: () => ({ errorKey: 'storage_exceeded' })
  },
  too_many_columns: {
    expression: 'Too many columns in result',
    getDetails: (msg) => ({ errorKey: 'too_many_columns', errorQuantity: msg.replace(/\D/g, '') })
  }
}

export function transformNotifications(data) {
  let notificationsToDisplay = data.items
    .filter((i) => i.details.panel_display || i.status === NOTIFICATION_STATUS.DISMISSED) // Dismissed notifications are needed to refresh notifications list, in case a notification is deleted from another tab
    .filter((i) => !(i.is_read && i.status === NOTIFICATION_STATUS.PROCESSING)) // Filter out if notification is read and processing
    .filter((i) => !i.details.data?.additional_properties?.liveLink) // Filter out live link notifications
    .filter((i) => !i.details.data?.additional_properties?.no_notification) // Filter out when 'no_notification' is true
    .map((i) => ({
      id: i.id,
      status: i.status,
      isRead: i.is_read,
      dataUpdatedAt: i.data_updated_at,
      workspaceId: i.workspace_id,
      details: {
        type: i.details.type,
        subType: i.details.subtype,
        viewName: i.details.data?.dataview_name,
        datasetName: i.details.data?.ds_name,
        targetDatasetName: i.details.data?.target_properties?.DS_NAME,
        targetDatabase: i.details.data?.target_properties?.database,
        targetHost: i.details.data?.target_properties?.host,
        targetIndex: i.details.data?.target_properties?.index,
        targetTable: `${i.details.data?.target_properties?.table || ''} `,
        targetEmails: i.details.data?.target_properties?.emails?.join(', '),
        exportedFilePath: i.details.data?.additional_data?.exported_file_path,
        downloadUrl: i.details.data?.additional_data?.url,
        fileType: i.details.data?.target_properties?.file_type?.toUpperCase(),
        liveLink: i.details.data?.additional_properties?.liveLink,
        reportName: i.details.data?.report_name,
        // Using details additional data, convert it into error info (key, params, etc)
        ..._getNotificationErrorDetails(
          i.details.data?.additional_data?.error ||
            i.details.data?.ERROR ||
            i.details.data?.additional_info ||
            i.details.data,
          i
        )
      }
    }))

  // Split between navbar notifications and toasts
  let toasts = []
  let notifications = []
  notificationsToDisplay.forEach((r) =>
    (r.status !== NOTIFICATION_STATUS.ERROR &&
    ['export', 'report'].includes(r.details.type) &&
    TOAST_SUBTYPES.includes(r.details.subType)
      ? toasts
      : notifications
    ).push(r)
  )

  return { toasts, notifications }
}

function _getNotificationErrorDetails(error, notification) {
  const msg = error?.message || error?.reason || error?.ERROR_CODE?.toString()
  if (!msg) return NOTIFICATION_ERRORS.generic.getDetails()

  // Get notification errorKey and related fields
  const errorKey = Object.keys(NOTIFICATION_ERRORS).find((e) => msg.includes(NOTIFICATION_ERRORS[e].expression))
  return NOTIFICATION_ERRORS[errorKey]?.getDetails(msg, notification)
}
