// Vue
import Vue from 'vue'

// Plugins
import emitter from '@/plugins/emitter'
import i18n from '@/plugins/i18n'

// API
import dataEditorApi from '@/modules/data-editor/api/data-editor.api'
import { transformMetadata, transformTakswiseInfo } from '@/api/global.transform.js'

// Constants
import { ERROR_CODE, STEP_CONTEXT, REQUESTS_STATUS, STEP_TYPES, INVERTED_DATA_TYPES_MAP } from '@/constants'

// Utils
import { deepDecode } from '@/utils/string'

// Dependencies
import { isEqual, uniqWith, isEmpty } from 'lodash'

const getInitialState = () => ({
  viewId: '',
  viewsMap: {
    /* viewId: {
      visibleRowCount: null,
      isPreview: false,
      displayProperties: {
        sort: null,
      },
      columns: [{
        id: "",
        name: "",
        isSelected: false,
        isVisible: true,
        type: "numeric",
        isExploreCardOpen: false,
        displayProperties: {
          format: {},
          originalName: "",
          width: 161
        }
      }],
      
    } */
  },
  isExploreSectionOpen: false,
  isLoading: false,
  isDisabled: false,
  isPipelineOpen: true,
  isMetricsPanelOpen: false,
  isMetricsModalOpen: false,
  previewModeSequence: null,
  isMammothManagedExport: false,
  functionsPanelFunction: '',
  functionsPanelContext: '',
  functionsPanelStep: null,
  exportStep: null,
  exportStepContext: null,
  newStepSequence: null,
  metadataBySequence: null,
  columnsUsedBySequence: null,
  pipelineDefaultMode: null,
  metrics: []
})

const getters = {
  view: (state, getters, rootState, rootGetters) => rootGetters['resources/getViewByViewId'](state.viewId),
  dataset: (state, getters, rootState, rootGetters) => rootGetters['resources/getDatasetByViewId'](state.viewId),
  isInited: (state) => !!state.viewsMap[state.viewId]?.isInited,
  displayProperties: (state) => state.viewsMap[state.viewId]?.displayProperties,
  columns: (state) => state.viewsMap[state.viewId]?.columns || [],
  selectedColumnIds: (state, getters) => getters.columns?.filter((column) => column.isSelected).map((col) => col.id),
  visibleRowCount: (state) => state.viewsMap[state.viewId]?.visibleRowCount,
  columnCount: (state, getters) => getters.columns?.length || 0,
  visibleColumnCount: (state, getters) => getters.columns?.filter((column) => column.isVisible).length || 0,
  isPreviewMode: (state) => state.previewModeSequence != null,
  visibleColumnIds: (state, getters) => getters.columns?.filter((column) => column.isVisible).map((col) => col.id),
  totalRowCount: (state) => state.viewsMap[state.viewId]?.totalRowCount,
  dependenciesInfo: (state) => state.viewsMap[state.viewId]?.dependenciesInfo,
  getPreviewModeStep: (state, getters, rootState) =>
    rootState.pipeline.steps.find((s) => s.sequence == state.previewModeSequence),
  isFunctionsPanelOpen: (state) => !!state.functionsPanelFunction,
  getColumnWithNewName: (state, getters) => (column) => {
    const col = getters.columns.find((col) => col.id == column.id)
    if (!isEmpty(col)) return { ...column, name: col.name }
    return column
  },
  getColumnsInErrorForCurrentStepByErrorCode: (state, getters) => (errorCode) => {
    const step = state.functionsPanelStep
    let columnsInError = []
    if (!step) return columnsInError
    const stepErrorDetails = step?.displayInfo?.error
    const errorDetailsInfo = stepErrorDetails?.info?.reference_errors || stepErrorDetails?.info
    if (
      (stepErrorDetails?.info?.error_code || stepErrorDetails?.code) === ERROR_CODE.REFERENCE_ERROR &&
      Array.isArray(errorDetailsInfo)
    ) {
      const filteredErrorDetailsInfo = uniqWith(errorDetailsInfo, isEqual) // Filter out deep duplicate objects that come from the BE
      columnsInError = filteredErrorDetailsInfo
        .filter((errorInfo) => errorInfo.error_code === errorCode)
        .map((errorColumnInfo) => {
          let column = { ...transformMetadata([errorColumnInfo.column])[0] }
          if (!column.id && column.name) {
            const realColumn = getters.columns.find((col) => col.name == column.name)
            if (realColumn) column = { ...realColumn }
          }
          if (errorColumnInfo.dataview) column.viewId = errorColumnInfo.dataview
          return { ...column, hasError: true }
        })
    }
    return columnsInError.map(getters.getColumnWithNewName).filter((col) => col.id)
  },
  getMetadataForCurrentStep: (state, getters) => {
    if ([STEP_CONTEXT.ADD, STEP_CONTEXT.DUPLICATE].includes(state.functionsPanelContext))
      return state.metadataBySequence?.[getters.view.properties.tasksQuantity]?.map(getters.getColumnWithNewName) || []

    const currentStep = state.functionsPanelStep
    if (!currentStep?.sequence) {
      const maxSequence = Math.max(...Object.keys(state.metadataBySequence || {}).map((s) => +s))
      return state.metadataBySequence?.[maxSequence]?.map(getters.getColumnWithNewName) || []
    }
    const sequenceForMetadata = currentStep.type == STEP_TYPES.ACTION ? currentStep.sequence : currentStep.sequence - 1
    return state.metadataBySequence?.[sequenceForMetadata]?.map(getters.getColumnWithNewName) || []
  },
  getMetadataForCurrentStepAugmentedWithMissingColumns: (state, getters) => {
    const missingColumns = getters.getColumnsInErrorForCurrentStepByErrorCode(ERROR_CODE.COLUMN_MISSING)
    const step = state.functionsPanelStep
    const metadata = getters.getMetadataForCurrentStep
    if (step?.isInError) {
      const filteredMetadata = metadata.filter((col) => !missingColumns.some((c) => c.id == col.id)) // Avoid duplicated columns
      return [...filteredMetadata, ...missingColumns]
    }
    return metadata || []
  },
  getMetadataIncludingTypeMismatchAndMissingColumns(state, getters) {
    return getters.getMetadataForCurrentStepAugmentedWithMissingColumns.map((column) => ({
      ...column,
      hasError:
        getters
          .getColumnsInErrorForCurrentStepByErrorCode(ERROR_CODE.COLUMN_TYPE_MISMATCH)
          .some((c) => c.id === column.id || c.name == column.name) || !!column.hasError
    }))
  },
  getCommonColumnValidation: (state, getters) => (column) => {
    const isColumnMissing = getters
      .getColumnsInErrorForCurrentStepByErrorCode(ERROR_CODE.COLUMN_MISSING)
      .some((col) => col.id == column?.id)
    if (isColumnMissing)
      return {
        isInvalid: true,
        message: i18n.tc('data_editor.error.columns_missing', 1, { columns: column.name }),
        errorCode: ERROR_CODE.COLUMN_MISSING
      }

    const isColumnTypeInvalid = getters
      .getColumnsInErrorForCurrentStepByErrorCode(ERROR_CODE.COLUMN_TYPE_MISMATCH)
      .some((col) => col.id == column?.id)
    if (isColumnTypeInvalid)
      return {
        isInvalid: true,
        message: i18n.t('data_editor.error.columns_type_mismatch', { columns: column.name }),
        errorCode: ERROR_CODE.COLUMN_TYPE_MISMATCH
      }

    return { isInvalid: false, message: '', errorCode: null }
  },
  getOriginalColumnName: (state, getters) => (colName) => {
    // Needed for the case of renamed columns
    return getters.columns.find((c) => c.name == colName)?.displayProperties?.originalName || colName
  },
  getPipelineDefaultMode: (state) =>
    state.pipelineDefaultMode ?? localStorage.getItem(`pipelineDefaultMode_${state.viewId}`) != 'false'
}

const mutations = {
  setViewId: (state, viewId) => (state.viewId = viewId),
  setLoading: (state, newState) => (state.isLoading = newState),
  setDisabled: (state, newState) => (state.isDisabled = newState),
  setIsInited: (state, isInited) => {
    Vue.set(state.viewsMap[state.viewId], 'isInited', isInited)
  },
  setTotalRowCount: (state, { viewId, totalRowCount }) => {
    if (viewId in state.viewsMap) Vue.set(state.viewsMap[viewId], 'totalRowCount', totalRowCount)
    else Vue.set(state.viewsMap, viewId, { totalRowCount })
  },
  setDependenciesInfo: (state, { viewId, dependenciesInfo }) => {
    Vue.set(state.viewsMap[viewId], 'dependenciesInfo', dependenciesInfo)
  },
  setSequence: (state, { viewId, sequence }) => {
    Vue.set(state.viewsMap[viewId], 'sequence', sequence)
  },
  setDisplayProperties: (state, { viewId, displayProperties }) => {
    Vue.set(state.viewsMap[viewId], 'displayProperties', displayProperties)
  },
  setColumns: (state, { viewId, columns }) => {
    const cols = deepDecode(columns.filter(Boolean))
    if (viewId in state.viewsMap) Vue.set(state.viewsMap[viewId], 'columns', cols)
    else Vue.set(state.viewsMap, viewId, { columns: cols })
  },
  setColumnSelection: (state, { isSelected, columnIds }) => {
    const view = state.viewsMap[state.viewId] || {}
    view.columns.filter((column) => columnIds.includes(column.id)).forEach((column) => (column.isSelected = isSelected))
  },
  setVisibleRowCount: (state, { viewId, visibleRowCount }) => {
    Vue.set(state.viewsMap[viewId], 'visibleRowCount', visibleRowCount)
  },
  setExploreCardColumns: (state, { columnIds = [] }) => {
    const view = state.viewsMap[state.viewId] || {}
    view.columns.forEach((column) => (column.isExploreCardOpen = columnIds.includes(column.id)))
    Vue.set(state.viewsMap, state.viewId, view)
  },
  setIsPipelineOpen: (state, isOpen) => (state.isPipelineOpen = isOpen),
  setIsExploreSectionOpen: (state, isOpen) => (state.isExploreSectionOpen = isOpen),
  setIsMetricsPanelOpen: (state, isOpen) => (state.isMetricsPanelOpen = isOpen),
  setIsMetricsModalOpen: (state, isOpen) => (state.isMetricsModalOpen = isOpen),
  setPreviewModeSequence: (state, previewModeSequence) => {
    state.previewModeSequence = previewModeSequence
    if (previewModeSequence) state.isMetricsPanelOpen = false
  },
  setIsMammothManagedExport: (state, isMammothManagedExport) => (state.isMammothManagedExport = isMammothManagedExport),
  setFunctionsPanelFunction: (state, functionsPanelFunction) => (state.functionsPanelFunction = functionsPanelFunction),
  setFunctionsPanelContext: (state, functionsPanelContext) => (state.functionsPanelContext = functionsPanelContext),
  setFunctionsPanelStep: (state, functionsPanelStep) => {
    if (functionsPanelStep) state.functionsPanelStep = JSON.parse(JSON.stringify(functionsPanelStep))
    else {
      state.functionsPanelStep = null
      state.newStepSequence = null
    }
  },
  setFunctionPanelErrorDetails: (state, errorInfo) => {
    Vue.set(state.functionsPanelStep, 'isInError', true)
    Vue.set(state.functionsPanelStep.displayInfo, 'error', errorInfo)
  },
  setExportStep: (state, step) => {
    if (step) state.exportStep = JSON.parse(JSON.stringify(step))
    else state.exportStep = null
  },
  setExportStepContext: (state, context) => (state.exportStepContext = context),
  setNewStepSequence: (state, newStepSequence) => (state.newStepSequence = newStepSequence),
  setMetadataBySequence: (state, metadataBySequence) => (state.metadataBySequence = metadataBySequence),
  setColumnsUsedBySequence: (state, columnsUsedBySequence) => (state.columnsUsedBySequence = columnsUsedBySequence),
  setMetrics: (state, metrics) => (state.metrics = metrics)
}

const actions = {
  async updateMetadataBySequence({ state, commit }) {
    const data = await dataEditorApi.getPipelineInfo(state.viewId)
    const taskwiseInfo = transformTakswiseInfo(data.taskwise_info)
    commit('setMetadataBySequence', taskwiseInfo.sequenceWiseMetadata)
    commit('setColumnsUsedBySequence', taskwiseInfo.sequenceWiseColumnsUsed)
  },
  async getVisibleRowCount({ state, commit, rootGetters }) {
    commit('setLoading', true)
    const visibleRowCount = await dataEditorApi.getRowCount({
      viewId: state.viewId,
      filterCondition: rootGetters['exploreSection/filterCondition'],
      sequence: state.previewModeSequence
    })
    commit('setVisibleRowCount', { viewId: state.viewId, visibleRowCount })
    commit('setLoading', false)
  },
  setErrorInfo({ commit }, missingColumns) {
    const missingColumnDetails = missingColumns.map((column) => ({
      column: {
        display_name: column.name,
        internal_name: column.id,
        type: INVERTED_DATA_TYPES_MAP[column.type]
      },
      error_code: ERROR_CODE.COLUMN_MISSING
    }))
    const errorInfo = {
      info: { error_code: ERROR_CODE.REFERENCE_ERROR, reference_errors: missingColumnDetails }
    }
    commit('setFunctionPanelErrorDetails', errorInfo)
  },
  selectColumns({ state, commit }, selectedColumnIds = []) {
    commit('setColumnSelection', {
      isSelected: true,
      columnIds: selectedColumnIds
    })
    const notSelectedColumns = state.viewsMap[state.viewId].columns
      .filter((column) => !selectedColumnIds.includes(column.id))
      .map((col) => col.id)
    commit('setColumnSelection', {
      isSelected: false,
      columnIds: notSelectedColumns
    })
  },
  async setHiddenColumns(store, { columnIds }) {
    store.commit('setLoading', true)
    const response = await dataEditorApi.setDisplayProperties({
      viewId: store.state.viewId,
      displayProperties: {
        HIDDEN_COLUMNS: columnIds
      }
    })

    const newColumns = store.getters.columns.map((column) => ({
      ...column,
      isVisible: !columnIds.includes(column.id)
    }))
    store.commit('setColumns', { viewId: store.state.viewId, columns: newColumns })
    store.commit('setLoading', false)
    return response
  },
  async setColumnRename(store, renamedColumns) {
    store.commit('setDisabled', true)
    const columns = store.getters.columns
    const displayProperties = {
      COLUMN_NAMES: renamedColumns,
      COLUMN_ORDER: columns.reduce((columnOrder, column, index) => {
        columnOrder[index] = column.id
        return columnOrder
      }, {})
    }
    const response = await dataEditorApi.setDisplayProperties({
      viewId: store.state.viewId,
      displayProperties
    })

    if (response.status === 'error') {
      store.commit('setDisabled', false)
    } else {
      const newColumns = response.columnDefs.map((column) => {
        const oldColumn = columns.find((col) => col.id === column.id)
        column.isExploreCardOpen = oldColumn.isExploreCardOpen
        return column
      })
      store.commit('setColumns', { viewId: store.state.viewId, columns: newColumns })
      store.commit('setDisabled', false)
    }
  },
  setExploreCardColumns({ getters, commit, dispatch }, { columnIds }) {
    commit('setExploreCardColumns', { columnIds })
    const columns = getters.columns.filter((column) => columnIds.includes(column.id))
    commit('setColumnSelection', {
      isSelected: false,
      columnIds
    })
    commit('setIsExploreSectionOpen', !!columns.length)
    dispatch('exploreSection/setExploreCardColumns', { columns }, { root: true })
  },
  async setDisplayProperties(store, displayProperties) {
    store.commit('setDisabled', true)
    const response = await dataEditorApi.setDisplayProperties({
      viewId: store.state.viewId,
      displayProperties
    })
    if (response.status !== REQUESTS_STATUS.ERROR) {
      const newColumns = response.columnDefs.map((column) => {
        const oldColumn = store.getters.columns.find((col) => col.id === column.id)
        column.isExploreCardOpen = oldColumn.isExploreCardOpen
        return column
      })
      store.commit('setColumns', { viewId: store.state.viewId, columns: newColumns })
      store.commit('setDisplayProperties', {
        viewId: store.state.viewId,
        displayProperties: response.displayProperties
      })
    }
    store.commit('setDisabled', false)
    return response
  },
  setFunctionsPanelData: ({ commit }, { functionKey, context, step }) => {
    commit('setFunctionsPanelFunction', functionKey)
    commit('setFunctionsPanelContext', context)
    commit('setFunctionsPanelStep', step)
  },
  async setGridViewData(store) {
    const { viewId } = store.state
    const response = await dataEditorApi.getViewInfo(viewId, store.state.previewModeSequence)
    if (!response) return

    const { columnDefs, rowCount, displayProperties, dependenciesInfo } = response
    store.commit('setColumns', { viewId, columns: columnDefs })
    store.commit('setTotalRowCount', { viewId, totalRowCount: rowCount })
    store.commit('setDependenciesInfo', { viewId, dependenciesInfo })
    store.commit('setDisplayProperties', { viewId, displayProperties })
    if (rowCount === 0) {
      store.commit('setVisibleRowCount', { viewId, visibleRowCount: 0 })
    } else if (!store.rootGetters['exploreSection/filterCondition']) {
      store.commit('setVisibleRowCount', { viewId, visibleRowCount: rowCount })
    } else {
      await store.dispatch('getVisibleRowCount')
    }
    if (store.getters.isPreviewMode)
      store.dispatch('selectColumns', store.getters.getPreviewModeStep?.affectedColumnsIds)

    emitter.emit('gridViewDataUpdated')
    store.commit('setIsInited', true)
  },
  async setPipelineDefaultMode({ state }, status) {
    state.pipelineDefaultMode = status
    localStorage.setItem(`pipelineDefaultMode_${state.viewId}`, status)
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
