import { ENV_KEY } from '@/constants'
import Keycloak from 'keycloak-js'

const KEYCLOAK_CONFIG = {
  clientId: ENV_KEY.VITE_APP_KEYCLOAK_CLIENT_ID,
  realm: ENV_KEY.VITE_APP_KEYCLOAK_REALM,
  url: ENV_KEY.VITE_APP_KEYCLOAK_URL
}

export const KEYCLOAK_INIT_OPTIONS = {
  checkLoginIframe: false,
  onLoad: 'login-required'
}

const keycloak = new Keycloak(KEYCLOAK_CONFIG)

export default keycloak
