import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { messages as mmStorybookMessages } from '@mammoth_developer/mm-storybook'
Vue.use(VueI18n)

function loadLocaleMessages() {
  const messages = { ...mmStorybookMessages }
  const locales = import.meta.glob('../**/locales/**/*.locale.json', { eager: true })
  for (const filePath of Object.keys(locales)) {
    const lang = filePath.split('/').slice(-1)[0].split('.')[1]
    const module = filePath.split('/').slice(-1)[0].split('.')[0]
    messages[lang] ||= {}
    messages[lang][module] = locales[filePath].default
  }
  return messages
}

const i18n = new VueI18n({
  locale: 'en',
  messages: loadLocaleMessages()
})

export default i18n
