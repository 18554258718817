<template>
  <div>
    <mm-modal
      v-model="computedIsModalOpen"
      :title="$t('data_library.data_addition.third_party.new_connection_sftp.title')"
      width="425"
      closable
    >
      <ul class="p-l-0 m-t-6 m-b-5" style="list-style: none">
        <li v-for="(item, idx) in items" :key="item.title">
          <div class="d-flex">
            <div class="d-flex-col flex-grow-1 m-r-4">
              <span class="mm-text--h300" v-text="item.title" />
              <span class="m-t-2" v-text="item.description" />
            </div>
            <mm-button
              class="m-y-auto flex-shrink-0"
              :label="$t('global.dictionary.select')"
              objective="secondary"
              @click="item.onClickFn()"
            />
          </div>
          <mm-divider v-if="idx < items.length - 1" class="m-y-5" />
        </li>
      </ul>
    </mm-modal>

    <data-library-data-addition-third-party-connection-new-sftp-ssh
      v-model="isSshConnectionModalVisible"
      :connector="connector"
      :selected-connection="selectedConnection"
      @close="$emit('close')"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
// Components
import DataLibraryDataAdditionThirdPartyConnectionNewSftpSsh from './new-sftp-ssh/new-sftp-ssh.vue'

// Constants
import { USER_EVENTS } from '@/constants'

export default {
  name: 'data-library-data-addition-third-party-connection-new-sftp',
  components: { DataLibraryDataAdditionThirdPartyConnectionNewSftpSsh },
  props: {
    value: Boolean,
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    selectedConnection: Object
    /*{ fields: [public_key, ...], ... }*/
  },
  data: () => ({
    isSshConnectionModalVisible: false
  }),
  computed: {
    computedIsModalOpen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.items = [
      {
        title: this.$t('data_library.data_addition.third_party.new_connection_sftp.user_password.title'),
        description: this.$t('data_library.data_addition.third_party.new_connection_sftp.user_password.description'),
        onClickFn: () => {
          this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_OPTION_SELECTED, {
            connector: this.connector.key,
            option: 'user_password'
          })
          this.computedIsModalOpen = false
          this.$emit('openRegularConnectionModal')
        }
      },
      {
        title: this.$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.title'),
        description: this.$t('data_library.data_addition.third_party.new_connection_sftp.ssh_key.description'),
        onClickFn: () => {
          this.$userEvents.save(USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_OPTION_SELECTED, {
            connector: this.connector.key,
            option: 'ssh_key'
          })
          this.computedIsModalOpen = false
          this.isSshConnectionModalVisible = true
        }
      }
    ]
  },
  watch: {
    computedIsModalOpen: {
      handler(val) {
        if (val && this.selectedConnection) {
          this.computedIsModalOpen = false
          if (this.selectedConnection.fields.public_key) this.isSshConnectionModalVisible = true
          else this.$emit('openRegularConnectionModal')
        }
      },
      immediate: true
    }
  }
}
</script>
