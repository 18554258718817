<template>
  <mm-modal v-model="isModalOpen" content-class="mm-migration-onboarding-modal p-a-0" width="450px" persistent closable>
    <div class="d-flex-col d-flex-center">
      <div class="img-wrapper d-flex-center p-t-6 p-b-5">
        <img class="m-b-6" src="@/assets/images/illustrations/hand_browser.svg" :alt="$t('global.migration.img_alt')" />
      </div>

      <div class="d-flex-col d-flex-center m-t-6 m-b-5">
        <p class="mm-text--h400 m-b-5">{{ $t('global.migration.title') }}</p>
        <p class="description m-b-7">{{ $t('global.migration.description') }}</p>
        <mm-button class="learn-more-btn" :label="$t('global.dictionary.learn_more')" @click="learnMore" />
      </div>
    </div>
  </mm-modal>
</template>

<script>
// Constants
import { ONBOARDING_FEATURES } from '@/constants'

// Utils
import { toggleOnboardingFlag } from '@/utils/onboarding'

export default {
  name: 'migration-onboarding',
  data: () => ({
    isModalOpen: false
  }),
  watch: {
    isModalOpen(val) {
      if (!val) toggleOnboardingFlag(ONBOARDING_FEATURES.MIGRATION_MAIN_MODAL)
    }
  },
  mounted() {
    this.isModalOpen =
      this.$store.getters.isMigrationOnboardingActive &&
      !this.$store.state.onboardingFlags[ONBOARDING_FEATURES.MIGRATION_MAIN_MODAL]
  },
  methods: {
    learnMore() {
      window.open('https://mammoth.io/blog/mammoth-new-interface/', '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '@mammoth_developer/mm-storybook/src/styles/spacing.scss';

.mm-migration-onboarding-modal {
  .img-wrapper {
    background: var(--mm-color--n30);
    width: 450px;
  }

  .description {
    color: var(--mm-color--n90);
    text-align: center !important;
  }

  .learn-more-btn button {
    width: 200px;
  }
}
</style>
