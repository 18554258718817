// API
import resourcesApi from '@/modules/resources/api/resources.api'
import notificationsApi from '@/components/navbar/navbar-notifications/api/notifications.api.js'
import authApi from '@/modules/auth/auth.api'

// Plugins
import store from '@/plugins/store'
import keycloak from '@/plugins/keycloak'
import router from '@/plugins/router'
import i18n from '@/plugins/i18n'

// Constants
import { NOTIFICATION_STATUS, REQUESTS_STATUS, ERROR_CODE } from '@/constants'
const REGULAR_UPDATE_TIMEOUT = 2500
const WINDOW_BLUR_UPDATE_TIMEOUT = 7000 // If window is not focused, getLatestUpdates is done less frequently

// Utils
import { clearLocalStorage } from '@/modules/auth/auth.store.js'

// Dependencies
import Vue from 'vue'

// Vars
let isTabFocused = true

export async function fetchData() {
  // Fetch needed data (resources, reports, data usage, etc) every X seconds

  await store.dispatch('getWorkspaces')
  _setTabListeners()
  try {
    await getLatestUpdates()
  } finally {
    const getUpdatesWithInterval = () =>
      setTimeout(
        // Using setTimeout instead of setInterval so that we can have a dynamic time interval
        async () => {
          await getLatestUpdates()
          getUpdatesWithInterval()
        },
        isTabFocused ? REGULAR_UPDATE_TIMEOUT : WINDOW_BLUR_UPDATE_TIMEOUT
      )

    setTimeout(getUpdatesWithInterval, REGULAR_UPDATE_TIMEOUT)
  }
}

export async function getLatestUpdates() {
  if (!keycloak.authenticated || !store.state.workspaceId || store.state.workspaceId == -1) return

  let storeProjectId = store.state.projectId
  if (storeProjectId < 0) {
    if (store.getters.getSubscribedProjects.length == 1) storeProjectId = store.getters.getSubscribedProjects[0].id
    else storeProjectId = router.currentRoute.params.projectId
    store.commit('setProjectId', storeProjectId)
  }

  const response = await resourcesApi.getResources(store.state.lastCheckedUntil, store.state.projectId)

  store.commit('setIsAppMaintenanceOngoing', response.status == 503)
  store.commit('setAreProjectResourcesFetched', true)

  if (response.data?.STATUS == REQUESTS_STATUS.FAILURE) {
    if (
      [ERROR_CODE.PROJECT_DOES_NOT_EXIST, ERROR_CODE.INVALID_WORKSPACE_ID, ERROR_CODE.USER_LACKS_PERMISSION].includes(
        response.data.ERROR_CODE
      )
    ) {
      store.commit('setProjectId', -1)
      router.push({ name: 'DataLibrary' })
      return
    }

    clearLocalStorage()
    await authApi.setSecurityCookie()
    return
  }

  const data = response

  // Preventing update resources with same repeated data
  if (data.lastCheckedUntil == store.state.lastCheckedUntil) return

  if (storeProjectId == store.state.projectId) store.commit('setLastCheckedUntil', data.lastCheckedUntil)
  if (data.resources) store.commit('resources/setResources', data.resources)
  if (data.pendingResources) store.commit('resources/setPendingResources', data.pendingResources)
  if (data.notifications?.length) store.dispatch('notifications/save', data.notifications)
  if (data.toasts?.length) _showToasts(data.toasts)
  if (data.isProjectChanged) store.dispatch('getWorkspaces', { invalidateCache: true })
  if (data.workspaceUsage) store.commit('setCurrentWorkspaceUsage', data.workspaceUsage)
  if (data.isReportsChanged) store.dispatch('resources/getReportResources')

  store.commit('setIsWorkspaceSnoozed', data.isWorkspaceSnoozed)
}

function _setTabListeners() {
  // Set listeners to check if tab has focus
  window.onblur = () => (isTabFocused = false)
  window.onfocus = () => {
    isTabFocused = true
    getLatestUpdates()
  }
}

function _showToasts(toasts) {
  toasts.forEach((t) => {
    // Show each toast and after that remove the toast, so that it is shown only once
    // This is needed because currently, on the BE, toasts and notifications are the same
    const toastMessage = i18n.t(`global.toast.${t.details.type}.${t.details.subType}.${t.status}`, t.details)
    Vue.prototype.$toast.show(toastMessage)
    if (t.status === NOTIFICATION_STATUS.COMPLETED) notificationsApi.remove(t.id)
    else if (t.status === NOTIFICATION_STATUS.PROCESSING) notificationsApi.read(t.id)
  })
}
