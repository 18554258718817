import store from '@/store'

export default [
  {
    path: '/report/:reportId',
    name: 'ReportsView',
    component: () =>
      store.state.isInterfaceV1Active ? import('./pages/reports-view.vue') : import('./pages/reports-view-v2.vue')
  }
]
