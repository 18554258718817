// Plugins
import keycloak from '@/plugins/keycloak'

// API
import authApi from '@/modules/auth/auth.api'

// Dependencies
import { jwtDecode } from 'jwt-decode'

const getInitialState = () => ({})

const clearKeysFromLocalStorage = (keys_selector) => {
  Object.keys(localStorage)
    .filter(keys_selector)
    .forEach((key) => localStorage.removeItem(key))
}

const keysToClear = ['storageClass', 'account_id', 'workspaceId', 'authenticated', 'card_filter']

const appKeysFilter = (key) => {
  return keysToClear.includes(key)
}

const mmCacheFilter = (key) => key.startsWith('mmCache_')

export const clearLocalStorage = () => {
  clearKeysFromLocalStorage(appKeysFilter)
  // clear the mmCache_ entries from local storage. This is being filled by the angular code. but logout logic is here, so cleaning it here.
  clearKeysFromLocalStorage(mmCacheFilter)
}

const getters = {
  isUserSuperAdmin: () => !!jwtDecode(keycloak.token)?.super_admin
}

const mutations = {}

const actions = {
  logout({ commit }) {
    commit('reset', null, { root: true })
    authApi.blacklistToken({ token: keycloak.token })
    clearLocalStorage()
    keycloak.logout()
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
