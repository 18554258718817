// Dependencies
import Vue from 'vue'

// Plugins
import i18n from '@/plugins/i18n'

export function showErrorToast(errorData, defaultMessage, toastConfig) {
  let errorCode, additionalInfo
  if (typeof errorData === 'object') {
    if (errorData.errorCode) errorCode = errorData.errorCode
    else if (errorData.error_info?.ERROR_CODE) errorCode = errorData.error_info.ERROR_CODE
    if (errorData.additionalInfo) additionalInfo = errorData.additionalInfo
  } else errorCode = errorData

  const content = i18n.te(`global.api.${errorCode}`, additionalInfo)
    ? i18n.t(`global.api.${errorCode}`)
    : defaultMessage || i18n.t('global.api.generic_error')
  Vue.prototype.$toast.show({ content, status: 'error', ...toastConfig })
}

export function showToast(toastData) {
  Vue.prototype.$toast.show(toastData)
}
