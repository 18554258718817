const reportStore = {
  namespaced: true,

  state: {
    isExploreSectionOpen: false,
    exploreConfig: null,
    filterCondition: null,
    columns: null,
    reportDisplayProps: null,
    reportFormatInfo: null,
    reportId: null,
    rowCount: null,
    dataset: {
      condition: null
    }
  },

  mutations: {
    setReport: (state, report) => {
      state.report = report
    },
    setExploreConfig: (state, props) => {
      state.exploreConfig = props
    },
    setReportColumns: (state, columns) => {
      state.columns = columns
    },
    setColumnSelection: (state, { isSelected, columnIds }) => {
      state.columns
        .filter((column) => columnIds.includes(column.id))
        .forEach((column) => (column.isSelected = isSelected))
    },
    setExploreCardColumns: (state, { columnIds = [] }) => {
      state.columns.forEach((column) => (column.isExploreCardOpen = columnIds.includes(column.id)))
    },
    setDisplayProps: (state, displayProps) => {
      state.reportDisplayProps = displayProps
    },
    setFormatInfo: (state, info) => {
      state.reportFormatInfo = info
    },
    setReportId: (state, id) => {
      state.reportId = id
    },
    setDatasetCondition: (state, condition) => {
      state.dataset.condition = condition
    },
    setIsExploreSectionOpen: (state, value) => {
      state.isExploreSectionOpen = value
    },
    setRowCount: (state, value) => {
      state.rowCount = value
    }
  },

  getters: {
    hasOpenCards: (state) => state.setIsExploreSectionOpen,
    columns: (state) => state.columns
  },

  actions: {
    setExploreCardColumns({ getters, commit, dispatch }, { columnIds }) {
      commit('setExploreCardColumns', { columnIds })
      const columns = getters.columns.filter((column) => columnIds.includes(column.id))
      commit('setColumnSelection', {
        isSelected: false,
        columnIds
      })
      dispatch('exploreSection/setExploreCardColumns', { columns }, { root: true })
    }
  }
}

export default reportStore
