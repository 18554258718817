import store from '@/store'

export default [
  {
    path: '/expired',
    name: 'TrialExpired',
    component: () =>
      store.state.isInterfaceV1Active ? import('./trial-expired.vue') : import('./trial-expired-v2.vue')
  }
]
