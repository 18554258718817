//Constants
import { STORAGE_WARNING_PERCENTAGE } from '@/constants'

// Plugins
import keycloak from '@/plugins/keycloak'

export default {
  watch: {
    '$store.state.usageInfo.workspace.current': function (newVal, oldVal) {
      // Warn user that has reached X percentage of usage (toast)
      const allowedUsage = this.$store.state.usageInfo.workspace.allowed
      if (allowedUsage <= 0 || oldVal == -1) return

      const oldUsedPercentage = (oldVal * 100) / allowedUsage
      const tempWarningPercentage = this.getDataUsedPercentage() >= 100 ? 100 : STORAGE_WARNING_PERCENTAGE

      if (oldUsedPercentage < tempWarningPercentage && tempWarningPercentage <= this.getDataUsedPercentage())
        this.$toast.show({
          content: this.$store.getters['isWorkspaceUsageLimitReached']
            ? this.$t('global.data_usage.max_reached')
            : this.$t('global.toast.usage_warning', { percentage: Math.floor(this.getDataUsedPercentage()) }),
          status: this.$store.getters['isWorkspaceUsageLimitReached'] ? 'error' : 'warning',
          actionLabel: this.$t('settings.workspace.storage.title'),
          actionFn: () => {
            const routeData = this.$router.resolve({
              name: 'SettingsWorkspace',
              params: this.$store.state,
              query: { dataStorage: true }
            })
            if (this.$store.state.isInterfaceV1Active) window.open(routeData.href, 'Mammoth_Settings')
            else window.location.href = routeData.href
          }
        })
    }
  },
  methods: {
    mixinDataUsage_verifyDataUsageWarning() {
      // Warn user that has reached X percentage of usage (banner on login)

      if (
        STORAGE_WARNING_PERCENTAGE <= this.getDataUsedPercentage() &&
        keycloak.sessionId != localStorage.getItem('keycloakSessionId')
      ) {
        this.$banner.show({
          content: this.$store.getters['isWorkspaceUsageLimitReached']
            ? this.$t('global.data_usage.max_reached')
            : this.$t('global.data_usage.warning_reached', { percentage: Math.floor(this.getDataUsedPercentage()) }),
          status: this.$store.getters['isWorkspaceUsageLimitReached'] ? 'error' : 'warning',
          actionLabel: this.$t('settings.workspace.storage.title'),
          actionFn: () => {
            const routeData = this.$router.resolve({
              name: 'SettingsWorkspace',
              params: this.$store.state,
              query: { dataStorage: true }
            })
            if (this.$store.state.isInterfaceV1Active) window.open(routeData.href, 'Mammoth_Settings')
            else window.location.href = routeData.href
          }
        })
      }

      localStorage.setItem('keycloakSessionId', keycloak.sessionId)
    },
    getDataUsedPercentage() {
      const allowedUsage = this.$store.state.usageInfo.workspace.allowed
      if (allowedUsage <= 0) return 0

      return (this.$store.state.usageInfo.workspace.current * 100) / allowedUsage
    }
  }
}
