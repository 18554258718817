import vuetify from '@/plugins/vuetify'

const getInitialState = () => ({
  isPreviewPanelOpen: false,
  selectedResourcesIds: [],
  selectedResourceBatches: []
})

const getters = {
  getAreAllRowDetailsVisible: (state) => {
    return state.isPreviewPanelOpen ? vuetify.framework.breakpoint.lgAndUp : vuetify.framework.breakpoint.mdAndUp
  },
  getSelectedResourceId: (state) => state.selectedResourcesIds[0]
}

const mutations = {
  setIsPreviewPanelOpen: (state, isPreviewPanelOpen) => {
    state.isPreviewPanelOpen = isPreviewPanelOpen
    if (!isPreviewPanelOpen) state.selectedResourcesIds = []
  },
  setSelectedResourcesIds: (state, selectedResourcesIds) => (state.selectedResourcesIds = selectedResourcesIds),
  setSelectedResourceBatches: (state, selectedResourceBatches) =>
    (state.selectedResourceBatches = selectedResourceBatches)
}

const actions = {}

export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
