import i18n from '@/plugins/i18n'
import moment from 'moment-timezone'
import { validators } from '@mammoth_developer/mm-storybook'

// Components
import DataLibraryDataAdditionThirdPartyConnectionNewSftp from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-connection/third-party-connection-new/new-sftp/new-sftp.vue'
import MetricsQuery from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-query/metrics-query.vue'
import MetricsCheckboxList from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-checkbox-list/metrics-checkbox-list.vue'
import MetricsFileUrl from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-file-url/metrics-file-url.vue'
import MetricsFileList from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-file-list/metrics-file-list.vue'
import MetricsSpreadsheet from '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-spreadsheet/metrics-spreadsheet.vue'
const MetricsSftp = () =>
  import(
    '@/modules/data-library/components/data-library-data-addition/third-party/third-party-metrics/metrics-sftp/metrics-sftp.vue'
  )

export const CONNECTOR_CATEGORIES = {
  ALL: 'all',
  ACCOUNTING: 'accounting',
  ANALYTICS: 'analytics',
  CLOUD_STORAGE: 'cloud_storage',
  CRM_MARKETING: 'crm_marketing',
  DATABASES: 'databases',
  ECOMMERCE: 'ecommerce'
}

// Pull mode values recognized by the backend
export const PULL_MODE_VALUES = { SAME: 'Pull same file', PATTERN: 'Pull next file based on name pattern' }

const connectorsMap = {
  BIGQUERY: {
    key: 'bigquery',
    name: 'Google BigQuery',
    icon: 'logo_full_big_query',
    mediumIcon: 'logo_google_big_query',
    smallIcon: 'bigquery',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      hasProfiles: true,
      fields: {
        connection_data: {
          component: 'mm-file-uploader',
          fieldCustomFn: (value) => JSON.stringify(value.json),
          props: {
            buttonLabel: i18n.t('data_library.data_addition.third_party.new_connection.upload_service_file'),
            allowedExtensions: ['json'],
            jsonParse: true,
            validations: [validators.required]
          }
        }
      },
      exportDefaultPort: 443
    },
    metrics: { component: MetricsQuery, quoteChar: '`' },
    scheduler: { enableReplaceWithNewData: true }
  },
  DATABRICKS: {
    key: 'databricks',
    name: 'Databricks',
    icon: 'logo_full_databricks',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      hasProfiles: true,
      profileAlias: i18n.t('data_library.data_addition.third_party.schema'),
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        host: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 443
          }
        },
        http_path: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.http_path'),
            validations: [validators.required]
          }
        },
        personal_access_token: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.personal_access_token'),
            validations: [validators.required],
            type: 'password'
          }
        },
        catalog: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.catalog'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery, quoteChar: '`', catalog: true },
    scheduler: { enableReplaceWithNewData: true }
  },
  DROPBOX: {
    key: 'dropbox',
    name: 'Dropbox',
    icon: 'logo_full_dropbox',
    category: CONNECTOR_CATEGORIES.CLOUD_STORAGE,
    connection: { authUrl: true },
    metrics: { component: MetricsFileUrl },
    scheduler: { skip: true }
  },
  FACEBOOK_ADS: {
    key: 'facebook_ads',
    name: 'Facebook Ads Manager',
    icon: 'logo_full_facebook_ads',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: { authUrl: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  GOOGLE_ADS: {
    key: 'ggl_ads',
    name: 'Google Ads',
    icon: 'logo_full_google_ads',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: { authUrl: true, hasProfiles: true, googleAuth: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  GOOGLE_ANALYTICS: {
    key: 'google_analytics',
    name: 'Google Analytics Universal',
    icon: 'logo_full_google_analytics',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: { authUrl: true, hasProfiles: true, googleAuth: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  GOOGLE_ANALYTICS_V4: {
    key: 'google_analyticsv4',
    name: 'Google Analytics 4',
    icon: 'logo_full_google_analytics_4',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: { authUrl: true, hasProfiles: true, googleAuth: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  GOOGLE_DRIVE: {
    key: 'google_drive',
    name: 'Google Drive',
    icon: 'logo_full_google_drive',
    category: CONNECTOR_CATEGORIES.CLOUD_STORAGE,
    connection: { authUrl: true, googleAuth: true },
    metrics: { component: MetricsFileUrl },
    scheduler: { skip: true }
  },
  GOOGLE_SHEETS: {
    key: 'google_sheets',
    name: 'Google Sheets',
    icon: 'logo_full_google_sheets',
    category: CONNECTOR_CATEGORIES.CLOUD_STORAGE,
    connection: { authUrl: true, googleAuth: true },
    metrics: {
      component: MetricsSpreadsheet,
      spreadsheet: {
        sheetNamePlaceholder: i18n.t('data_library.data_addition.third_party.new_connection.sheet_name_placeholder')
      }
    },
    scheduler: { hideUsc: true }
  },
  HUBSPOT: {
    key: 'hubspot',
    name: 'HubSpot',
    icon: 'logo_full_hubspot',
    category: CONNECTOR_CATEGORIES.CRM_MARKETING,
    connection: { authUrl: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  MAILCHIMP: {
    key: 'mailchimp',
    name: 'Mailchimp',
    icon: 'logo_full_mailchimp',
    category: CONNECTOR_CATEGORIES.CRM_MARKETING,
    connection: { authUrl: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  MIXPANEL: {
    key: 'mixpanel',
    name: 'Mixpanel',
    icon: 'logo_full_mixpanel',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: {
      onAppAuth: true,
      fields: {
        mixpanel_api_secret: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.api_secret'),
            validations: [validators.required]
          }
        },
        project_timezone: {
          component: 'mm-dropdown',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.timezone'),
            validations: [validators.required],
            placeholder: i18n.t('data_library.data_addition.third_party.new_connection.search_timezone'),
            searchable: true,
            items: moment.tz.names()
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsCheckboxList },
    scheduler: { hiddenFrequencies: { minutely: true, hourly: true } }
  },
  MONGODB: {
    key: 'mongodb',
    name: 'MongoDB',
    icon: 'logo_full_mongodb',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        hostname: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 27017
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  MSSQL: {
    key: 'mssql',
    name: 'SQL Server',
    icon: 'logo_full_sqlserver',
    mediumIcon: 'logo_sqlserver',
    smallIcon: 'sqlserver',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      showIpInfo: true,
      hasProfiles: true,
      fields: {
        hostname: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 1433
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery, defaultSchemaPrefix: 'dbo' },
    scheduler: { enableReplaceWithNewData: true }
  },
  MYSQL: {
    key: 'mysql',
    name: 'MySQL',
    icon: 'logo_full_mysql',
    mediumIcon: 'logo_mysql',
    smallIcon: 'mysql',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        hostname: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 3306
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery, quoteChar: '`' },
    scheduler: { enableReplaceWithNewData: true }
  },
  PARQUET: {
    key: 'parquet',
    name: 'Parquet',
    icon: 'logo_full_parquet',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      hasProfiles: true,
      fields: {
        access_key: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.access_key'),
            validations: [validators.required]
          }
        },
        account_name: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.account_name'),
            validations: [validators.required]
          }
        },
        container: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.container'),
            validations: [validators.required]
          }
        },
        identity_type: { props: { value: 'datalake' } }
      }
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  POSTGRES: {
    key: 'postgres',
    name: 'PostgreSQL',
    icon: 'logo_full_postgresql',
    mediumIcon: 'logo_postgresql',
    smallIcon: 'postegresql',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      hasProfiles: true,
      profileAlias: i18n.t('data_library.data_addition.third_party.schema'),
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        hostname: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 5432
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery, defaultSchemaPrefix: 'public' },
    scheduler: { enableReplaceWithNewData: true }
  },
  REDSHIFT: {
    key: 'redshift',
    name: 'Amazon Redshift',
    icon: 'logo_full_amazon_redshift',
    mediumIcon: 'logo_amazon_redshift',
    smallIcon: 'aws_redshift',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        hostname: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 5439
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  SALESFORCE: {
    key: 'salesforcecdata',
    name: 'Salesforce',
    icon: 'logo_full_salesforce',
    category: CONNECTOR_CATEGORIES.CRM_MARKETING,
    connection: {
      userInputAuthUrl: true,
      fields: {
        instance_url: {
          component: 'mm-text-input',
          props: {
            title: `${i18n.t('data_library.data_addition.third_party.new_connection.instance_url')} (${i18n.t(
              'data_library.data_addition.third_party.new_connection.domain'
            )})`,
            validations: [validators.required],
            value: 'https://login.salesforce.com',
            ref: 'serverUrl'
          }
        }
      },
      generateAuthUrlFn: (integrationInfo, userInput) =>
        userInput + integrationInfo.authUrl + encodeURIComponent(`&server=${userInput}`)
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  SENDGRID: {
    key: 'sendgrid',
    name: 'SendGrid',
    icon: 'logo_full_sendgrid',
    category: CONNECTOR_CATEGORIES.CRM_MARKETING,
    connection: {
      onAppAuth: true,
      showIpInfo: true,
      fields: {
        api_key: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.api_secret'),
            validations: [validators.required]
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.name'),
            validations: [validators.required]
          }
        }
      }
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  SFTP: {
    key: 'sftp',
    name: 'SFTP',
    icon: 'logo_full_sftp',
    smallIcon: 'sftp',
    category: CONNECTOR_CATEGORIES.CLOUD_STORAGE,
    connection: {
      customNewConnectionComponent: DataLibraryDataAdditionThirdPartyConnectionNewSftp,
      onAppAuth: true,
      fields: {
        domain: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.domain'),
            validations: [validators.required]
          }
        },
        port: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.port'),
            validations: [validators.required],
            type: 'number',
            mask: '#####',
            value: 22
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        name: {
          component: 'mm-text-input',
          props: {
            title:
              i18n.t('data_library.data_addition.third_party.new_connection.display_name') +
              i18n.t('global.dictionary.optional')
          }
        }
      }
    },
    metrics: {
      component: MetricsSftp,
      fileTypes: '.txt .csv .tsv .psv .xls .xlsx .zip .bz2 .gz .tar .7z'
    },
    scheduler: { hideUsc: true }
  },
  SHAREPOINT: {
    key: 'sharepoint',
    name: 'SharePoint',
    icon: 'logo_full_sharepoint',
    category: CONNECTOR_CATEGORIES.CLOUD_STORAGE,
    connection: {
      userInputAuthUrl: true,
      fields: {
        url: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.server_url'),
            validations: [validators.required],
            ref: 'serverUrl'
          }
        }
      },
      generateAuthUrlFn: (integrationInfo, userInput) =>
        integrationInfo.authUrl +
        encodeURIComponent(`&server=${userInput}`) +
        '&scope=' +
        encodeURIComponent(`${userInput}/${integrationInfo.scope}`)
    },
    metrics: { component: MetricsFileList, fileTypes: '.csv .tsv .txt .xls .xlsx' },
    scheduler: { skip: true }
  },
  SHOPIFY: {
    key: 'shopify',
    name: 'Shopify',
    icon: 'logo_full_shopify',
    category: CONNECTOR_CATEGORIES.ECOMMERCE,
    connection: {
      userInputAuthUrl: true,
      fields: {
        instance_url: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.shop_url'),
            validations: [validators.required],
            ref: 'serverUrl'
          }
        }
      },
      generateAuthUrlFn: (integrationInfo, userInput) => `${userInput}${integrationInfo.authUrl}`
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  SNOWFLAKE: {
    key: 'snowflake',
    name: 'Snowflake',
    icon: 'logo_full_snowflake',
    category: CONNECTOR_CATEGORIES.DATABASES,
    connection: {
      profileAlias: i18n.t('data_library.data_addition.third_party.schema'),
      onAppAuth: true,
      hasProfiles: true,
      showIpInfo: true,
      fields: {
        url: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.host_url'),
            validations: [validators.required]
          }
        },
        username: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.username'),
            validations: [validators.required]
          }
        },
        password: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.password'),
            validations: [validators.required],
            type: 'password'
          }
        },
        database: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.database'),
            validations: [validators.required]
          }
        },
        warehouse: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.warehouse'),
            validations: [validators.required]
          }
        },
        account: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.account'),
            validations: [validators.required]
          }
        },
        role: {
          component: 'mm-text-input',
          props: {
            title: i18n.t('data_library.data_addition.third_party.new_connection.role_name'),
            validations: [validators.required]
          }
        }
      }
    },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  XERO: {
    key: 'xero',
    name: 'Xero',
    icon: 'logo_full_xero',
    category: CONNECTOR_CATEGORIES.ACCOUNTING,
    connection: { authUrl: true, hasProfiles: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  },
  ZOOM: {
    key: 'zoom',
    name: 'Zoom',
    icon: 'logo_full_zoom',
    category: CONNECTOR_CATEGORIES.ANALYTICS,
    connection: { authUrl: true },
    metrics: { component: MetricsQuery },
    scheduler: { enableReplaceWithNewData: true }
  }
}

// Compute each connectors hash
export const CONNECTORS_MAP = Object.keys(connectorsMap).reduce(
  (o, key) => ({ ...o, [key]: { ...connectorsMap[key], hash: btoa(connectorsMap[key].key) } }),
  {}
)

export const CONNECTORS = Object.values(CONNECTORS_MAP)

// Includes all connectors (both import and export)
// Needed because some connectors are used only for export, not import
export const EXPORT_CONNECTORS_MAP = {
  ...CONNECTORS_MAP,
  ELASTICSEARCH: {
    key: 'elasticsearch',
    name: 'Elasticsearch',
    smallIcon: 'elastic_search',
    mediumIcon: 'logo_elasticsearch',
    connection: { exportDefaultPort: 9243 }
  }
}

export const EXPORT_CONNECTORS = Object.values(EXPORT_CONNECTORS_MAP)
