<template>
  <div class="mm-data-addition-third-party-metrics-file-list height-100">
    <div v-if="isLoading" class="loading height-100">
      <mm-loader class="m-x-auto" size="large" />
    </div>

    <div v-else class="content">
      <div class="content-wrapper m-x-2">
        <mm-dropdown
          v-model="selectedFile"
          :items="fileList"
          item-text="name"
          :title="$t('data_library.data_addition.third_party.file')"
          :error="!!errorMessage"
          :message="errorMessage"
          searchable
          hide-details
        />

        <div class="d-flex m-t-2 m-b-4">
          <mm-icon name="information" />
          <span class="m-l-2 mm-text--caption-regular">
            {{ $t('data_library.data_addition.third_party.file_list_info', { types: connector.metrics.fileTypes }) }}
          </span>
        </div>
      </div>

      <div class="actions d-flex p-a-2">
        <mm-button :label="$t('global.dictionary.back')" objective="tertiary" @click="$emit('back')" />
        <mm-button
          class="m-l-auto"
          :label="$t(`global.dictionary.${connector.scheduler?.skip ? 'submit' : 'next'}`)"
          :disabled="!selectedFile"
          :loading="isSubmitted || isSubmitLoading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// API
import dataLibraryApi from '@/modules/data-library/api/data-library.api'

export default {
  name: 'data-library-data-addition-third-party-metrics-file-list',
  props: {
    connector: {
      type: Object,
      required: true
      /*{
        key: string,
        name: string,
        icon: string,
        category: string,
        connection: {...},
        metrics: {...}
      }*/
    },
    connection: {
      type: Object,
      required: true
      /*{
        value: string,
      }*/
    },
    isSubmitLoading: Boolean
  },
  data: () => ({
    selectedFile: null,
    isLoading: true,
    isSubmitted: false,
    errorMessage: '',
    fileList: []
  }),
  async created() {
    this.metricsInfo = await dataLibraryApi.getMetricsInfo(
      this.connector.hash,
      this.connection.value,
      this.$store.state.projectId
    )

    if (this.metricsInfo.fileList) {
      this.fileList = this.metricsInfo.fileList
    } else {
      if (this.metricsInfo.message) this.$toast.show({ content: this.metricsInfo.message, status: 'error' })
      this.$emit('back')
    }

    this.isLoading = false
  },
  methods: {
    async submit() {
      this.isSubmitted = true
      this.errorMessage = ''

      const response = await dataLibraryApi.validateFileList(
        this.connector.hash,
        this.connection.value,
        this.selectedFile,
        this.$store.state.projectId,
        () => this._isDestroyed
      )

      if (response?.is_valid)
        this.$emit('submit', {
          selectedFile: this.selectedFile,
          table: { name: this.selectedFile.split('/').slice(-1)[0] },
          pullMode: ''
        })
      else this.errorMessage = this.$t('data_library.data_addition.third_party.invalid_file')

      this.isSubmitted = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mm-data-addition-third-party-metrics-file-list {
  flex-direction: column;
  display: flex;

  .loading {
    align-items: center;
    display: flex;
  }

  .content {
    flex-direction: column;
    display: flex;
    height: 100%;

    .content-wrapper {
      flex-direction: column;
      display: flex;
      flex-grow: 1;
    }
  }

  .actions {
    align-items: end;
    flex-grow: 1;
  }
}
</style>
